import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export default function NewsGrid() {
  const { t, i18n }=useTranslation();
  const navigate=useNavigate();
  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }
  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item">
                      <a href="" onClick={(x) => navigateTo(x, '/')}>{t('home')}</a>
                    </li>
                    <li className="separator" />
                    <li
                      className="axil-breadcrumb-item active"
                      aria-current="page"
                    >
                      {t('news')}
                    </li>
                  </ul>
                  <h1 className="title">{t('news')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img
                      src="assets/images/product/product-45.png"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrumb Area  */}
        {/* Start Blog Area  */}
        <div className="axil-blog-area axil-section-gap">
          <div className="news-search axil-single-widget mt--40 widget_search">
            <h6 className="widget-title">Search</h6>
            <form className="blog-search" action="#">
              <button className="search-button">
                <i className="fal fa-search" />
              </button>
              <input type="text" placeholder="Search" />
            </form>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row g-5">
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-10.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Digital Art's</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Keeping yourself safe when buying NFTs on eTrade
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-11.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Photography</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Important updates for listing and delisting your
                              NFTs
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-12.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Music</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              10 tips for avoiding scams and staying safe on the
                              decentralized web
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-10.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Sports</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Keeping yourself safe when buying NFTs on eTrade
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-11.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Virtual Studio</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Important updates for listing and delisting your
                              NFTs
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-12.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Utility</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              10 tips for avoiding scams and staying safe on the
                              decentralized web
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-10.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Sketch</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Keeping yourself safe when buying NFTs on eTrade
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-11.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Digital Art's</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Important updates for listing and delisting your
                              NFTs
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-10.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Digital Art's</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Keeping yourself safe when buying NFTs on eTrade
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-blog blog-grid">
                      <div className="inner">
                        <div className="thumbnail">
                          <a href="javascript:void(0);">
                            <img
                              src="assets/images/blog/blog-11.png"
                              alt="Blog Images"
                            />
                          </a>
                          <div className="blog-category">
                            <a href="#">Photography</a>
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a href="javascript:void(0);">
                              Important updates for listing and delisting your
                              NFTs
                            </a>
                          </h5>
                          <div className="read-more-btn">
                            <a
                              className="axil-btn right-icon"
                              href="javascript:void(0);"
                            >
                              Read More{" "}
                              <i className="fal fa-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-pagination">
                  <nav className="navigation pagination" aria-label="Products">
                    <ul className="page-numbers">
                      <li>
                        <span
                          aria-current="page"
                          className="page-numbers current"
                        >
                          1
                        </span>
                      </li>
                      <li>
                        <a className="page-numbers" href="#">
                          2
                        </a>
                      </li>
                      <li>
                        <a className="page-numbers" href="#">
                          3
                        </a>
                      </li>
                      <li>
                        <a className="page-numbers" href="#">
                          4
                        </a>
                      </li>
                      <li>
                        <a className="page-numbers" href="#">
                          5
                        </a>
                      </li>
                      <li>
                        <a className="next page-numbers" href="#">
                          <i className="fal fa-arrow-right" />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            {/* End post-pagination */}
          </div>
          {/* End .container */}
        </div>
        {/* End Blog Area  */}
      </main>
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
