import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Checkout from "../Checkout/Checkout";
import secureLocalStorage from "react-secure-storage";
import { checkSecureLogin, customerLogout, getCustomerId, getSecureStorage, getTokenKey, getTokenValue, setSecureStorage } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, setStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import swal from "sweetalert";

export default function Header() {

  const { t, i18n } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const [getLang, setLang] = useState('en');
  const [getCartArr, setCartArr] = useState([]);
  const [getCartTotal, setCartTotal] = useState(0.00);
  const [getCartCount, setCartCount] = useState(0);
  const [getSearch, setSearch] = useState([]);
  const [getTotalSearch, setTotalSearch] = useState(0);
  const [getContact, setContact] = useState({});

  const changeLanguage = (lng) => {

    i18n.changeLanguage(lng);
    const currentLanguage = i18n.language;
    let currDirEle = document.getElementById('head-lang');
    let currDir = currDirEle.getAttribute('dir');

    var style_link = document.getElementById('style-type');

    if (currentLanguage == 'ar') {
      style_link.href = '../assets/css/style-rtl.min.css';
    } else {
      style_link.href = '../assets/css/style.min.css';
    }
  };

  useEffect(() => {
    if (i18n.language == 'en-US') {
      setStorage('i18nextLng', 'en');
      setLang('en');
    } else {
      setLang(i18n.language);
    }
    getCart();
    getContactApi();
  }, [])


  const getNoauthCart = () => {
    const cartdata = getSecureStorage('cart');
    if (cartdata && cartdata.length != 0) {
      var formdata = new FormData();
      formdata.append("cart_data", JSON.stringify(cartdata));
      var requestOptions = {
        method: 'POST',
        body: formdata,
      };

      fetch(`${config.baseUrl}/books/get/cart/noauth`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            var finalcart = [];
            finalcart = (result.data).map((item, index) => {
              let name = [];
              let product_id = item.product_id;
              let cart_id = item.id;
              let cart_qty = item.qty;
              let image = '';
              let price = '';
              if (item.product) {
                for (let key in (item.product)) {
                  name = JSON.parse((item.product)['name']);
                  image = (item.product)['thumb_img'];
                  price = (item.product)['new_price'];
                }
              }
              return {
                product_id: product_id,
                cart_id: cart_id,
                cart_qty: cart_qty,
                image: image,
                name: name,
                price: price
              }
            })

            let total = 0;

            finalcart.map((item, index) => {
              total += item.price
            })

            setCartCount(finalcart.length);
            setCartTotal(total);

            setCartArr(finalcart);
          } else {
            swal({
              icon: result.status,
              title: result.msg
            })
          }
        }).catch(error => {
          swal({
            icon: 'error',
            title: 'Something Went Wrong'
          })
        }).finally(() => {

        });
    } else {
      setCartArr([]);
      setCartCount(0);
      setCartTotal(0);
    }

  }

  const getCart = () => {
    if (!checkSecureLogin()) {
      getNoauthCart()
      return false;
    }
    var myHeaders = new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata = new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions = {
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/get/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          var finalcart = [];
          finalcart = (result.data).map((item, index) => {
            let name = [];
            let product_id = item.product_id;
            let cart_id = item.id;
            let cart_qty = item.qty;
            let image = '';
            let price = '';
            if (item.product) {
              for (let key in (item.product)) {
                name = JSON.parse((item.product)['name']);
                image = (item.product)['thumb_img'];
                price = (item.product)['new_price'];
              }
            }
            return {
              product_id: product_id,
              cart_id: cart_id,
              cart_qty: cart_qty,
              image: image,
              name: name,
              price: price
            }
          })

          let total = 0;

          finalcart.map((item, index) => {
            total += item.price
          })

          setCartCount(finalcart.length);
          setCartTotal(total);

          setCartArr(finalcart);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }


  function deleteItem(x, id, product_id) {
    x.preventDefault();
    var btn_ele = x.currentTarget;
    var btn_html = btn_ele.innerHTML;
    if (!checkSecureLogin() || id == 0) {
      const cartdata = getSecureStorage('cart');
      if (cartdata && cartdata.length != 0) {
        const updatedCart = cartdata.filter(item => item.product_id !== product_id);
        setSecureStorage('cart', updatedCart)
      } else {
        setSecureStorage('cart', []);
        setCartCount(0);
        setCartTotal(0);
      }
      getNoauthCart();
      return false;
    }

    if (btn_ele.getAttribute('disabled') == 'true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';

    var myHeaders = new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata = new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("cart_id", id);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/remove/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          getCart();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML = btn_html;
      });
  }


  useEffect(() => {
    const handleScroll = () => {
      const topHeader = document.querySelector('.axil-header-top');
      const headerCampaign = document.querySelector('.header-top-campaign');
      const menu = document.querySelector('.axil-mainmenu');
      const stickyPlaceHolder = document.querySelector('#axil-sticky-placeholder');

      if (!topHeader || !menu || !stickyPlaceHolder) return;

      const topHeaderH = topHeader.offsetHeight || 0;
      const headerCampaignH = headerCampaign ? headerCampaign.offsetHeight || 0 : 0;

      const targetScroll = topHeaderH + headerCampaignH;

      if (window.scrollY > targetScroll) {
        setIsSticky(true);
        menu.classList.add('axil-sticky');
        stickyPlaceHolder.style.height = `${menu.offsetHeight}px`;
      } else {
        setIsSticky(false);
        menu.classList.remove('axil-sticky');
        stickyPlaceHolder.style.height = '0';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  async function addTocart(x, id) {
    x.preventDefault();
    var btn_ele = x.currentTarget;
    var btn_html = btn_ele.innerHTML;
    if (!checkSecureLogin()) {

      let cartdata = {
        qty: 1,
        product_id: id
      };

      let currentCart = getSecureStorage('cart') ?? [];
      const isProductInCart = currentCart.some(item => item.product_id === cartdata.product_id);

      if (!isProductInCart) {
        let newcart = [...currentCart, cartdata];
        setSecureStorage('cart', newcart);
      }

      Toastify({
        text: t('Added To Cart'),
        duration: 3000,
        style: {
          background: config.toastSuccess,
        }
      }).showToast();
      return false;
    }

    if (btn_ele.getAttribute('disabled') == 'true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';

    var myHeaders = new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());

    var formdata = new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions = {
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };
    console.log(getTokenValue());
    fetch(`${config.baseUrl}/books/add/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML = btn_html;
      });
  }

  const searchBar = (x) => {
    x.preventDefault();
    var value = (x.currentTarget.value).trim();
    if (value.length > 3) {
      var myHeaders = new Headers();
      myHeaders.append(getTokenKey(), getTokenValue());
      myHeaders.append('Accept-Language', getLang);

      var formdata = new FormData();
      formdata.append("keyword", value);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${config.baseUrl}/books/search`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setTotalSearch((result.data).length);
            setSearch(result.data);
          } else {
            Toastify({
              text: t('Something Went Wrong'),
              duration: 3000,
              style: {
                background: config.toastError,
              }
            }).showToast();
            return false;
          }
        }).catch(error => {
          console.log(error);
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }).finally(() => {
        });
    } else {
      setSearch([]);
    }
  }

  const navigate = useNavigate();


  function addToWishlist(x, id) {
    x.preventDefault();
    var btn_ele = x.currentTarget;
    var btn_html = btn_ele.innerHTML;
    if (!checkSecureLogin()) {
      Toastify({
        text: t('Please Login'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    if (btn_ele.getAttribute('disabled') == 'true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';

    var myHeaders = new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata = new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions = {
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/setwishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML = btn_html;
      });
  }

  const contact = () => {
    return navigate("/contact")
  }
  const shop = () => {
    return navigate("/shop")
  }
  const news = () => {
    return navigate("/newslist")
  }
  const wishlist = () => {
    return navigate("/wishlist")
  }
  const cart = () => {
    return navigate("/cart")
  }
  const checkout = () => {
    return navigate("/checkout")
  }
  const about = () => {
    return navigate("/about")
  }
  const signup = () => {
    return navigate("/signup")
  }
  const signin = () => {
    return navigate("/signin")
  }

  const logout = () => {
    customerLogout();
    return navigate('/');
  }

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }


  const getContactApi = () => {
    var formdata = new FormData();
    var requestOptions = {
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/common/getcontact`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status === 'success') {
          setContact(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  return (
    <div>

      <header className="header axil-header header-style-1">
        <div className="header-top-campaign">
          <div className="container position-relative">
            <div className="campaign-content">
              <p>{t('Do you have any query')} <a href="#">{t('Contact us')} at: {getContact.phone}</a></p>
            </div>
          </div>
          <button className="remove-campaign"><i className="fal fa-times" /></button>
        </div>
        <div className="axil-header-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="header-top-dropdown">
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {(i18n.language == 'ar') ? 'Arabic' : 'English'}

                    </button>
                    <ul className="dropdown-menu">
                      <li><button className="dropdown-item" onClick={() => changeLanguage('en')}>English</button></li>
                      <li><button className="dropdown-item" onClick={() => changeLanguage('ar')}>Arabic</button></li>

                    </ul>
                  </div>
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      USD
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">USD</a></li>
                      <li><a className="dropdown-item" href="#">AUD</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="header-top-link">
                  <ul className="quick-link">
                    <li><a href="#">{t('help')}</a></li>
                    {
                      (!checkSecureLogin()) ? <li> <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/signup')}>{t('join_us')}</a></li> : <></>
                    }

                    {
                      (checkSecureLogin()) ? <li onClick={logout}><a href="javascript:void(0);" >{t('Log Out')}</a></li> : <li onClick={(x) => navigateTo(x, '/signin')}><a href="javascript:void(0);" >{t('sign_in')}</a></li>
                    }

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Mainmenu Area  */}
        <div id="axil-sticky-placeholder" />
        <div className="axil-mainmenu">
          <div className="container">
            <div className="header-navbar">
              <div className="header-brand">
                <a href="/" className="logo logo-dark">
                  <img src="../assets/images/logo/logo.png" alt="Site Logo" />
                </a>
                <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/')} className="logo logo-light">
                  <img src="../assets/images/logo/logo-light.png" alt="Site Logo" />
                </a>
              </div>
              <div className="header-main-nav">
                {/* Start Mainmanu Nav */}
                <nav className="mainmenu-nav">
                  <button className="mobile-close-btn mobile-nav-toggler"><i className="fas fa-times" /></button>
                  <div className="mobile-nav-brand">
                    <a href="/" className="logo">
                      <img src="../assets/images/logo/logo.png" alt="Site Logo" />
                    </a>
                  </div>
                  <ul className="mainmenu">
                    <li >
                      <a href="javascript:void(0);">{t('home')}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/shop')}>{t('books')}</a>
                    </li>

                    {/* <li>
                      <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/gallery')}>{t('gallery')}</a>
                    </li> */}
                    <li>
                      <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/author')}>{t('authors')}</a>
                    </li>

                    <li><a href="javascript:void(0);" onClick={() => {
                      return navigate("/journals")
                    }}>{t('journals')}</a></li>
                    
                    <li><a href="javascript:void(0);" onClick={about}>{t('about')}</a></li>


                    <li className="menu-item-has-children">
                      <a href="javascript:void(0);">{t('Other')}</a>
                      <ul className="axil-submenu">
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/gallery')}>{t('Events & Gallery')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/calendar')}>{t('Event Calendar')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/directorsmessage')}>{t('directorsmessage')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/projectsandprogram')}>{t('projectsandprogram')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/publishingforms')}>{t('publishingforms')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/researchintegrity')}>{t('researchintegrity')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/peerreview')}>{t('peerreview')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/post')}>{t('post')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/catalogue')}>{t('catalogue')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/newslist')}>{t('news')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/author')}>{t('author')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/journals')}>{t('journals')}</a></li>
                        <li><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/contact')}>{t('contact')}</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                {/* End Mainmanu Nav */}
              </div>
              <div className="header-action">
                <ul className="action-list">
                  <li className="axil-search">
                    <a href="javascript:void(0)" className="header-search-icon" title="Search">
                      <i className="flaticon-magnifying-glass" />
                    </a>
                  </li>
                  <li className="wishlist">
                    <a href="javascript:void(0);" onClick={wishlist}>
                      <i className="flaticon-heart" />
                    </a>
                  </li>
                  <li className="shopping-cart">
                    <a href="javascript:void(0);" onClick={getCart} className="cart-dropdown-btn">
                      <span className="cart-count">{getCartCount}</span>
                      <i className="flaticon-shopping-cart" />
                    </a>
                  </li>
                  <li className="my-account">
                    <a href="javascript:void(0)" onClick={(x) => navigateTo(x, '/myaccount')}>
                      <i className="flaticon-person" />
                    </a>
                    <div className="my-account-dropdown">
                      <span className="title">QUICKLINKS</span>
                      <ul>
                        <li>
                          <a href="my-account.html">My Account</a>
                        </li>
                        <li>
                          <a href="#">Initiate return</a>
                        </li>
                        <li>
                          <a href="#">Support</a>
                        </li>
                        <li>
                          <a href="#">Language</a>
                        </li>
                      </ul>
                      <div className="login-btn">
                        <a href="/signin" className="axil-btn btn-bg-primary">Login</a>
                      </div>
                      <div className="reg-footer text-center">No account yet? <a href="sign-up.html" className="btn-link">REGISTER HERE.</a></div>
                    </div>
                  </li>
                  <li className="axil-mobile-toggle">
                    <button className="menu-btn mobile-nav-toggler">
                      <i className="flaticon-menu-2" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* End Mainmenu Area */}
      </header>
      <div className="header-search-modal" id="header-search-modal">
        <button className="card-close sidebar-close">
          <i className="fas fa-times" />
        </button>
        <div className="header-search-wrap">
          <div className="card-header">
            <form action="javascript:void(0);">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  name="prod-search"
                  id="prod-search"
                  onKeyUp={searchBar}
                  placeholder={`${t('Write Something')}...`}
                />
                <button type="submit" className="axil-btn btn-bg-primary">
                  <i className="far fa-search" />
                </button>
              </div>
            </form>
          </div>
          <div className="card-body">
            <div className="search-result-header">
              <h6 className="title">{getTotalSearch} {t('Result Found')}</h6>
              <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/shop')} className="view-all">
                {t('View All')}
              </a>
            </div>
            <div className="psearch-results">
              {
                getSearch?.map((item, index) => {
                  return (
                    <div className="axil-product-list" key={index}>
                      <div className="thumbnail">
                        <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/' + item.id)}>
                          <img style={{ height: '120px', width: "120px" }} src={`${config.baseImg}/${item.thumb_img}`} alt="Yantiti Leather Bags"
                          />
                        </a>
                      </div>
                      <div className="product-content">

                        <h6 className="product-title text-start">
                          <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/' + item.id)}>{(getLang == 'en-US' || getLang == 'en') ? item.name_decoded.en : item.name_decoded.ar}</a>
                        </h6>
                        <div className="product-price-variant text-start">
                          <span className="price current-price">{getCurrency()} {toDecimal(item.new_price)}</span>
                          <span className="price old-price">{getCurrency()} {toDecimal(item.old_price)}</span>
                        </div>
                        <div className="product-cart">
                          <a href="javascript:void(0);" onClick={(x) => addTocart(x, item.id)} className="cart-btn">
                            <i className="fal fa-shopping-cart" />
                          </a>
                          <a href="javascript:void(0);" onClick={(x) => addToWishlist(x, item.id)} className="cart-btn">
                            <i className="fal fa-heart" />
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="cart-dropdown" id="cart-dropdown">
        <div className="cart-content-wrap">
          <div className="cart-header">
            <h2 className="header-title">{t('Cart review')}</h2>
            <button className="cart-close sidebar-close"><i className="fas fa-times" /></button>
          </div>
          <div className="cart-body">
            <ul className="cart-item-list">

              {
                getCartArr?.map((item, index) => {
                  return (
                    <li className="cart-item" key={index}>
                      <div className="item-img">
                        <a href="javascript:void(0);"><img src={`${config.baseImg}/${item.image}`} alt="Commodo Blown Lamp" /></a>
                        <button className="close-btn" onClick={(x) => deleteItem(x, item.cart_id, item.product_id)}><i className="fas fa-times" /></button>
                      </div>
                      <div className="item-content">

                        <h3 className="item-title text-start"><a href="javascript:void(0);">
                          {
                            (getLang == 'en-US' || getLang == 'en') ? item.name.en : item.name.ar
                          }
                        </a></h3>
                        <div className="item-price text-start"><span className="currency-symbol">{getCurrency()}</span>&nbsp;{toDecimal(item.price)}</div>
                        <div className="pro-qty item-quantity">
                          <input type="number" className="quantity-input" readOnly value={item.cart_qty} />
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="cart-footer">
            <h3 className="cart-subtotal">
              <span className="subtotal-title">{t('Subtotal')}:</span>
              <span className="subtotal-amount">{getCurrency()}&nbsp;{getCartTotal}</span>
            </h3>
            <div className="group-btn">
              <a href="javascript:void(0);" className="axil-btn btn-bg-primary viewcart-btn" onClick={cart}>View Cart</a>
              <a href="javascript:void(0);" className="axil-btn btn-bg-secondary checkout-btn" onClick={checkout}>Checkout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}