import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";


import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue, setCustomerLogin } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, removeStorage, setStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import swal from "sweetalert";

export default function Checkout() {
  const { t, i18n }=useTranslation();
  const navigate=useNavigate();
  const [getCountry, setCountry]=useState([]);
  const [getState, setState]=useState([]);
  const [getLang, setLang]=useState('en');

  const [getCartArr, setCartArr]=useState([]);
  const [getCartTotal, setCartTotal]=useState(0.00);
  const [getCartCount, setCartCount]=useState(0);


  const countryRef=useRef();
  const stateRef=useRef();
  const nameRef=useRef();
  const addressRef1=useRef();
  const addressRef2=useRef();
  const phoneRef=useRef();
  const noteRef=useRef();
  const emailRef=useRef();


  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  useEffect(() => {
    setLang(i18n.language);
    getCountryApi();
    getCart();
  }, [])


  const getCart=() => {
    if (!checkSecureLogin()) {
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/get/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          var finalcart=[];
          finalcart=(result.data).map((item, index) => {
            let name=[];
            let product_id=item.product_id;
            let cart_id=item.id;
            let cart_qty=item.qty;
            let image='';
            let price='';
            let max_qty=0;
            if (item.product) {
              for (let key in (item.product)) {
                name=JSON.parse((item.product)['name']);
                image=(item.product)['thumb_img'];
                price=(item.product)['new_price'];
                max_qty=(item.product)['qty'];
              }
            }
            return {
              product_id: product_id,
              cart_id: cart_id,
              cart_qty: cart_qty,
              image: image,
              name: name,
              price: price,
              max_qty: max_qty
            }
          })

          let total=0;

          finalcart.map((item, index) => {
            total+=item.price
          })
          setCartCount(finalcart.length);
          setCartTotal(total);
          console.log(finalcart);
          setCartArr(finalcart);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  const getCountryApi=() => {
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/common/getcountry`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result);
          setCountry(result.data);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  function getCountryValue(x) {
    x.preventDefault();
    let country_id=(x.target.value);

    if (country_id=='') {
      setState([]);
      return false; 
    }

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("country_id", country_id);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/common/getcity`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result);
          setState(result.data);
        } else {
          Toastify({
            text: result.msg,
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  const checkout=(x) => {
    x.preventDefault();
    var btn_ele=x;
    let name=(nameRef.current.value).trim();
    let country=(countryRef.current.value).trim();
    let state=(stateRef.current.value).trim();
    let address1=(addressRef1.current.value).trim();
    let address2=(addressRef2.current.value).trim();
    let phone=(phoneRef.current.value).trim();
    let email=(emailRef.current.value).trim();
    let note=(noteRef.current.value).trim();

    if (name=='') {
      Toastify({
        text: t('Name Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (country=='') {
      Toastify({
        text: t('Country Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (state=='') {
      Toastify({
        text: t('State Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (address1=='') {
      Toastify({
        text: t('Address Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (phone=='') {
      Toastify({
        text: t('Phone Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }


    if (!checkSecureLogin()) {
      return false;
    }

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);
    btn_ele.disabled=true;
    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("billing_name", name);
    formdata.append("billing_country", country);
    formdata.append("billing_address", address1);
    formdata.append("billing_city", state);
    formdata.append("billing_phone", phone);
    formdata.append("billing_email", email);
    formdata.append("order_note", note);
    formdata.append("pay_amount", getCartTotal);
    formdata.append("cart_id", getCartTotal);
    formdata.append("shipping_different", 0);
    formdata.append("payment_method", 'cod');
    formdata.append("lang", getLang);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/customer/order/checkout`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {

          (nameRef.current.value='');
          (countryRef.current.value='');
          (stateRef.current.value='');
          (addressRef1.current.value='');
          (addressRef2.current.value='');
          (phoneRef.current.value='');
          (emailRef.current.value='');
          (noteRef.current.value='');
          setStorage('order_id', result.data.order_id);
          setStorage('invoice_number', result.data.invoice_number);
          return navigate('/thankyou');

        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        (btn_ele).disabled=false;
      });

  }

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Checkout Area  */}
        <div className="axil-checkout-area axil-section-gap">
          <div className="container">
            <br />
            <br />
            <form action="#">
              <div className="row">
                <div className="col-lg-6">
                  {/* <div className="axil-checkout-notice">
                    <div className="axil-toggle-box">
                      <div className="toggle-bar"><i className="fas fa-user" /> {t('returning_customer?')} <a href="javascript:void(0)" className="toggle-btn">{t('click_here_to_login')} <i className="fas fa-angle-down" /></a>
                      </div>
                      <div className="axil-checkout-login toggle-open">
                        <p>If you didn't Logged in, Please Log in first.</p>
                        <div className="signin-box">
                          <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" />
                          </div>
                          <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" name="password" />
                          </div>
                          <div className="form-group mb--0">
                            <button type="submit" className="axil-btn btn-bg-primary submit-btn">Sign In</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="axil-toggle-box">
                      <div className="toggle-bar"><i className="fas fa-pencil" /> {t('have_a_coupon?')} <a href="javascript:void(0)" className="toggle-btn">{t('click_here_to_enter_your_code')} <i className="fas fa-angle-down" /></a>
                      </div>
                      <div className="axil-checkout-coupon toggle-open">
                        <p>If you have a coupon code, please apply it below.</p>
                        <div className="input-group">
                          <input placeholder="Enter coupon code" type="text" />
                          <div className="apply-btn">
                            <button type="submit" className="axil-btn btn-bg-primary">Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="axil-checkout-billing">
                    <h4 className="title mb--40">{t('billing_details')}</h4>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>{t('name')} <span>*</span></label>
                          <input ref={nameRef} type="text" id="first-name" placeholder={t('name')} />
                        </div>
                      </div>

                    </div>

                    <div className="form-group">
                      <label>{t('country_region')} <span>*</span></label>
                      <select ref={countryRef} onChange={(x) => getCountryValue(x)} name="country" id="country" className="select2">
                        <option value="">Select Country</option>
                        {
                          getCountry?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{(getLang=='en-US'||getLang=='en')? item.name:item.ar_name}</option>
                            )
                          })
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label>{t('State')}</label>
                      <select ref={stateRef} name="country" id="country" className="select2">
                        <option value="">{t('Select State')}</option>
                        {
                          getState?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{(getLang=='en-US'||getLang=='en')? item.name:item.ar_name}</option>
                            )
                          })
                        }
                      </select>
                    </div>

                    <div className="form-group">
                      <label>{t('street_address')} <span>*</span></label>
                      <input ref={addressRef1} type="text" id="address1" className="mb--15" placeholder={t('house_number_and_street_name')} />
                      <input ref={addressRef2} type="text" id="address2" placeholder={t('apartment,_suite,_unit,_etc._(optonal)')} />
                    </div>


                    <div className="form-group">
                      <label>{t('phone')} <span>*</span></label>
                      <input ref={phoneRef} type="tel" id="phone" />
                    </div>
                    <div className="form-group">
                      <label>{t('email_address')} <span>*</span></label>
                      <input ref={emailRef} type="email" id="email" />
                    </div>

                    <div className="form-group different-shippng">
                      <div className="toggle-bar">
                        {/* <a href="javascript:void(0)" className="toggle-btn">
                          <input type="checkbox" id="checkbox2" name="diffrent-ship" />
                          <label htmlFor="checkbox2">{t('ship_to_a_different_address?')}</label>
                        </a> */}
                      </div>
                      <div className="toggle-open">
                        <div className="form-group">
                          <label>Country/ Region <span>*</span></label>
                          <select id="Region">
                            <option value={3}>Australia</option>
                            <option value={4}>England</option>
                            <option value={6}>New Zealand</option>
                            <option value={5}>Switzerland</option>
                            <option value={1}>United Kindom (UK)</option>
                            <option value={2}>United States (USA)</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Street Address <span>*</span></label>
                          <input type="text" id="address1" className="mb--15" placeholder="House number and street name" />
                          <input type="text" id="address2" placeholder="Apartment, suite, unit, etc. (optonal)" />
                        </div>
                        <div className="form-group">
                          <label>Town/ City <span>*</span></label>
                          <input type="text" id="town" />
                        </div>
                        <div className="form-group">
                          <label>Country</label>
                          <input type="text" id="country" />
                        </div>
                        <div className="form-group">
                          <label>Phone <span>*</span></label>
                          <input type="tel" id="phone" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{t('other_notes_(optional)')}</label>
                      <textarea ref={noteRef} id="notes" rows={2} placeholder={t('notes_about_your_order,_e.g._speacial_notes_for_delivery.')} defaultValue={""} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="axil-order-summery order-checkout-summery">
                    <h5 className="title mb--20">{t('your_order')}</h5>
                    <div className="summery-table-wrap">
                      <table className="table summery-table">
                        <thead>
                          <tr>
                            <th>{t('product')}</th>
                            <th>{t('subtotal')}</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            getCartArr?.map((item, index) => {
                              return (

                                <tr className="order-product" key={index}>
                                  <td>{(getLang=='en-US'||getLang=='en')? item.name.en:item.name.ar} <span className="quantity">x{item.cart_qty}</span></td>
                                  <td>{getCurrency()} {toDecimal(item.price)}</td>
                                </tr>
                              )
                            })
                          }

                          <tr className="order-shipping">
                            <td colSpan={2}>
                              <div className="shipping-amount">
                                <span className="title">{t('shipping_method')}</span>
                                <span className="amount">0.00</span>
                              </div>
                              <div className="input-group">
                                <input type="radio" id="radio1" name="shipping" defaultChecked />
                                <label htmlFor="radio1">Free Shippping</label>
                              </div>

                            </td>
                          </tr>
                          <tr className="order-total">
                            <td>{t('total')}</td>
                            <td className="order-total-amount">{getCurrency()} {toDecimal(getCartTotal)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="order-payment-method">

                      <div className="single-payment">
                        <div className="input-group">
                          <input checked type="radio" id="radio5" name="payment" />
                          <label htmlFor="radio5">{t('cash_on_delivery')}</label>
                        </div>
                        <p>Pay with cash upon delivery.</p>
                      </div>

                    </div>
                    <button type="button" onClick={checkout} className="axil-btn btn-bg-primary checkout-btn">{t('process_to_checkout')}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* End Checkout Area  */}
      </main>
      <br />
      <br />
      <br />
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}