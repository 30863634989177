import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export default function ForgotPassword() {
  const { t, i18n }=useTranslation();
  const navigate=useNavigate();
  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }

  return (
    <div className="axil-signin-area">
      <div className="signin-header">
        <div className="row align-items-center">
          <div className="col-xl-4 col-sm-6">
            <a href="/" className="site-logo"><img src="./assets/images/logo/logo.png" alt="logo" /></a>
          </div>
          <div className="col-md-2 d-lg-block d-none">
            <a href="/signin" className="back-btn"><i className="far fa-angle-left" /></a>
          </div>
          <div className="col-xl-6 col-lg-4 col-sm-6">
            <div className="singin-header-btn">
              <p onClick={(x) => navigateTo(x, '/signin')}>{t('already_a_member?')}</p>
              <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/signin')} className="sign-up-btn axil-btn btn-bg-secondary">{t('sign_in')}</a>
            </div>
          </div>
        </div>
      </div>
      {/* End Header */}
      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="axil-signin-banner bg_image bg_image--10">
            <h3 className="title">{t('we_offer_the_best_products')}</h3>
          </div>
        </div>
        <div className="col-lg-6 offset-xl-2">
          <div className="axil-signin-form-wrap">
            <div className="axil-signin-form">
              <h3 className="title">{t('forgot_password?')}</h3>
              <p className="b2 mb--55">{t('enter_the_email_address_you_used_when_you_joined_and_we’ll_send_you_instructions_to_reset_your_password.')}</p>
              <form className="singin-form">
                <div className="form-group">
                  <label>{t('email')}</label>
                  <input type="email" className="form-control" name="email" />
                </div>
                <div className="form-group">
                  <button type="submit" className="axil-btn btn-bg-primary submit-btn">{t('send_reset_instructions')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
