const config={
    baseUrl: "https://quadmin.atimaad.com/api",
    baseImg: "https://quadmin.atimaad.com/public/uploads",
    toastError: "linear-gradient(to right, rgb(255, 95, 109), rgb(255, 195, 113))",
    toastSuccess: "linear-gradient(to right, rgb(0, 176, 155), rgb(150, 201, 61))",
    ebookUrl: "https://quadmin.atimaad.com/public/uploads"
}

export default config;

export function checkNumber(num) {
    return !isNaN(parseInt(num));
}

export function setStorage(key, value) {
    localStorage.setItem(key, value);
}

export function getStorage(key) {
    return localStorage.getItem(key);
}

export function removeStorage(key) {
    localStorage.removeItem(key);
}

export function checkLogin() {
    let user=localStorage.getItem('user_id')
    if (user===null||user===undefined||user===''||user==='undefined'||user==='null') {
        const currentURL=window.location.href;
        const lastSlashIndex=currentURL.lastIndexOf('/');
        const modifiedURL=lastSlashIndex!==-1? currentURL.substring(0, lastSlashIndex+1):currentURL;
        window.location.replace('/login');
    }
}

export function checkStorage(key) {
    let temp=localStorage.getItem(key);
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') { //not exist
        return false;
    } else {  //if exist
        return true
    }
}

export function checkData(key) {
    let temp=key;
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') { //not exist
        return false;
    } else {  //if exist
        return true
    }
}

export function getCurrency() {
    let temp=localStorage.getItem('get_currency');
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') {
        return 'QR';
    } else {
        return temp;
    }
}

export function toDecimal(num) {
    if (isNaN(parseFloat(num))) {
        return 0.00;
    }
    return parseFloat(num).toFixed(2);
}


