import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import './author.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function Author() {
    const { t, i18n }=useTranslation();
    const [getLang, setLang]=useState('en');
    const [getJournal, setJournal]=useState([]);
    const [getAuthor, setAuthor]=useState([]);
    const [getAuthorDetail, setAuthorDetail]=useState({});
    const [getCategory, setCategory]=useState([]);
    const nameRef=useRef();
    const categoryRef=useRef();

    const [show, setShow]=useState(false);
    const handleClose=() => {
        setAuthorDetail({});
        setShow(false)
    };


    useEffect(() => {
        getAuthorApi();
        getCategoryApi();
        setLang(i18n.language);
    }, []);

    function getCategoryApi() {

        var formdata=new FormData();
        var requestOptions={
            method: 'POST',
            body: formdata,
        };
        var catData=[];
        fetch(`${config.baseUrl}/common/getallbookcategory`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status==='success') {
                    (result.data).map((data, index) => {
                        let name=JSON.parse(data.name);
                        name.id=data.id;
                        name.image=data.image;
                        catData.push(name);
                    })
                    setCategory(catData);
                } else {
                    swal({
                        icon: result.status,
                        title: result.msg
                    })
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Something Went Wrong'
                })
            }).finally(() => {

            });
    }
    const changeLanguage=(lng) => {
        i18n.changeLanguage(lng);
        const currentLanguage=i18n.language;
        let currDirEle=document.getElementById('head-lang');
        let currDir=currDirEle.getAttribute('dir');
        if (currentLanguage=='ar') {
            currDirEle.setAttribute('dir', 'rtl');
        } else {
            currDirEle.setAttribute('dir', 'ltr');
        }
    };

    function handleShow(x, id) {
        x.preventDefault();

        var formdata=new FormData();
        formdata.append('id', id);
        var requestOptions={
            method: 'POST',
            body: formdata,
        };
        fetch(`${config.baseUrl}/getauthor/detail`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status==='success') {
                    setAuthorDetail(result.data);
                } else {
                    swal({
                        icon: result.status,
                        title: result.msg
                    })
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Something Went Wrong'
                })
            }).finally(() => {
            });

        setShow(true);
    }


    function getAuthorApi() {
        let nameVal=(nameRef.current.value).trim();
        let catVal=(categoryRef.current.value).trim();
        var formdata=new FormData();
        formdata.append('name', nameVal);
        formdata.append('category', catVal);

        var requestOptions={
            method: 'POST',
            body: formdata,
        };
        fetch(`${config.baseUrl}/getauthor`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status==='success') {
                    setAuthor(result.data.data);
                } else {
                    swal({
                        icon: result.status,
                        title: result.msg
                    })
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Something Went Wrong'
                })
            }).finally(() => {
            });
    }

    const search=() => {
        getAuthorApi();
    }
    function limitWord(text) {
        const words=text.split(' ');
        if (words.length>30) {
            return words.slice(0, 30).join(' ')+'......';
        } else {
            return text;
        }
    }

    return (
        <>
            <div className="main-container">
                {/* <h2 className="journals-title lead text-center" style={{ padding: '6px', margin: '0 0 1em 0', letterSpacing: '1px', textTransform: 'uppercase' }}>
                    {t('authors')} </h2> */}
                <h1 class="title text-center my-4 mb-5">{t('authors')}</h1>


                <div className="container-fluid mb-4">
                    <div className="author-filter">
                        <div className="row">
                            <div className="col">
                                <form className="singin-form">
                                    <div className="form-group mb-0">
                                        <label>{t('Filter By Name')}</label>
                                        <input ref={nameRef} type="text" className="form-control" name="name" />
                                    </div>
                                </form>
                            </div>
                            <div className="col">
                                <form className="singin-form ">
                                    <div className="form-group mb-0">
                                        <label>{t('Filter By Category')}</label>
                                        <select ref={categoryRef} name="category" id="category" className="select2">
                                            <option value="">{t('Select Category')}</option>
                                            {
                                                getCategory?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{(getLang=='en-US'||getLang=='en')? item.en:item.ar}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="col">
                                <button style={{ width: 'auto', padding: '10px 38px' }} onClick={search} className="axil-btn btn-bg-primary btn-sm">Search</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="journals">
                    <ul className="row media-list">

                        {
                            getAuthor?.map((item, index) => {
                                return (
                                    <li className="media col-md-6" key={index}>
                                        <div className="media-left">
                                            <a href="javascript:void(0);">
                                                <img className="media-object" src={`${config.baseImg}/${item.image}`} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <h3 className="media-heading">
                                                <a href="javascript:void(0);" rel="bookmark">
                                                    {(getLang=='en-US'||getLang=='en')? item.name:item.name_ar}
                                                </a>
                                            </h3>
                                            <div className="description">
                                                <p className="para">{(getLang=='en-US'||getLang=='en')? limitWord(item.description):limitWord(item.description_ar)}</p>
                                            </div>
                                            <ul className="nav nav-pills">
                                                <li className="view" style={{ border: 'none' }}>
                                                    <a href="javascript:void(0);" onClick={(x) => handleShow(x, item.id)}>
                                                        {t('More')}...
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>



            <Modal className="author-modal" show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0">{(getLang=='en-US'||getLang=='en')? (getAuthorDetail.name):(getAuthorDetail.name_ar)}</Modal.Title>
                    <button onClick={handleClose} className="modal-custom-close"><i className="fas fa-times"></i></button>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <img className="modal-img" src={`${config.baseImg}/${getAuthorDetail.image}`} />
                                    <p>{(getLang=='en-US'||getLang=='en')? (getAuthorDetail.description):(getAuthorDetail.description_ar)}</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>




        </>

    )
}