import React from "react";
import { useEffect,useState,useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export default function ResetPassword() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage = i18n.language;
    let currDirEle = document.getElementById('head-lang');
    let currDir = currDirEle.getAttribute('dir');
  
    if (currentLanguage == 'ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };
    return(
                <div className="axil-signin-area">
                  {/* Start Header */}
                  <div className="signin-header">
                    <div className="row align-items-center">
                      <div className="col-xl-4 col-sm-6">
                        <a href="/" className="site-logo"><img src="./assets/images/logo/logo.png" alt="logo" /></a>
                      </div>
                      <div className="col-md-2 d-lg-block d-none">
                        <a href="forgot-password.html" className="back-btn"><i className="far fa-angle-left" /></a>
                      </div>
                      <div className="col-xl-6 col-lg-4 col-sm-6">
                        <div className="singin-header-btn">
                          <p>{t('already_a_member?')} <a href="/signin" className="sign-in-btn">{t('sign_in')}</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Header */}
                  <div className="row">
                    <div className="col-xl-4 col-lg-6">
                      <div className="axil-signin-banner bg_image bg_image--10">
                        <h2 className="title">{t('we_offer_the_best_products')}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 offset-xl-2">
                      <div className="axil-signin-form-wrap">
                        <div className="axil-signin-form">
                          <h3 className="title mb--35">{t('reset_password')}</h3>
                          <form className="singin-form">
                            <div className="form-group">
                              <label>{t('new_password')}</label>
                              <input type="password" className="form-control" name="password1" defaultValue={123456789} />
                            </div>
                            <div className="form-group">
                              <label>{t('confirm_password')}</label>
                              <input type="password" className="form-control" name="password2" defaultValue={123456789} />
                            </div>
                            <div className="form-group">
                              <button type="submit" className="axil-btn btn-bg-primary submit-btn">{t('reset_password')}</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
