import React from "react";
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export default function PublishingForms() {
    const { t, i18n }=useTranslation();

    // Change the language when a button is clicked
    const changeLanguage=(lng) => {
        i18n.changeLanguage(lng);
        const currentLanguage=i18n.language;
        let currDirEle=document.getElementById('head-lang');
        let currDir=currDirEle.getAttribute('dir');

        if (currentLanguage=='ar') {
            currDirEle.setAttribute('dir', 'rtl');
        } else {
            currDirEle.setAttribute('dir', 'ltr');
        }
    };
    return (
        <div>
            <main className="main-wrapper">
                {/* Start Breadcrumb Area  */}
                <div className="axil-breadcrumb-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="inner">
                                    <ul className="axil-breadcrumb">
                                        <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                                        <li className="separator" />
                                        <li className="axil-breadcrumb-item active" aria-current="page">{t('publishing_forms')}</li>
                                    </ul>
                                    <h1 className="title">{t('publishing_forms')}</h1>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <div className="container">
                <div className="journal-page journals">
                    <div className="row media-list">
                        <a target="_blank" href="https://www.qu.edu.qa/static_file/qu/qupress/Files/Book%20proposal.pdf" className="author-col col-md-4">
                            <div className="card" style={{ width: '25rem' }}>
                                <img src="assets/images/publishform/Publish Book_img.png" />
                                <div className="card-body py-4">
                                    <p className="card-text">{t('Book proposal')}</p>
                                </div>
                            </div>
                        </a>
                        <a href="https://www.qu.edu.qa/static_file/qu/qupress/Files/Book%20translation%20proposal%20(1).docx" download={true} className="author-col col-md-4">
                            <div className="card" style={{ width: '25rem' }}>
                                <img src="assets/images/publishform/translatebook_img.png" />
                                <div className="card-body py-4">
                                    <p className="card-text">{t('Book translation proposal')}</p>
                                </div>
                            </div>
                        </a>
                        <a href="javascript:void(0);" className="author-col col-md-4">
                            <div className="card" style={{ width: '25rem' }}>
                                <img src="assets/images/publishform/publish a journal_img.png" />
                                <div className="card-body py-4">
                                    <p className="card-text">{t('Journal proposal')}</p>
                                </div>
                            </div>
                        </a>

                        <div className="publish-title text-center mb-4">
                            <h3 className="title">To speed this process, we recommend that all
                                submissions <br /> to be via the online system as follows:</h3>
                        </div>
                        <div className="mb-5">
                            <iframe
                                style={{

                                    width: '560px',
                                    height: '315px',
                                }}
                                src="https://www.youtube.com/embed/B9op4gsYrDs"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}