// checkLogin.js

import secureLocalStorage from "react-secure-storage";

export const checkSecureLogin=() => {
    const temp=secureLocalStorage.getItem("customer_id");
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') {
        return false;
    }
    return true
};

export const getCustomerId=() => {
    const temp=secureLocalStorage.getItem("customer_id");
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') {
        return false;
    }
    return temp
};

export const getTokenValue=() => {
    const temp=secureLocalStorage.getItem("Authorization");
    if (temp===null||temp===undefined||temp===''||temp==='undefined'||temp==='null') {
        return false;
    }
    return temp
};

export const getTokenKey=() => {
    return "Authorization";
};

export const customerLogout=() => {
    secureLocalStorage.removeItem("customer_id");
    secureLocalStorage.removeItem("Authorization");
};

export const setCustomerLogin=(customer_id, Authorization) => {
    secureLocalStorage.setItem("customer_id", customer_id);
    secureLocalStorage.setItem("Authorization", "Bearer "+Authorization);
};

export const setSecureStorage=(key, value) => {
    secureLocalStorage.setItem(key, value);
};

export const getSecureStorage=(key) => {
    return secureLocalStorage.getItem(key);
};

export const removeSecureStorage=(key) => {
    return secureLocalStorage.removeItem(key);
};

export const redirectLogin=() => {
    return window.location.replace('/login');
};
