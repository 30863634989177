import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import swal from "sweetalert";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18n from 'i18next';
import "./product.css";
import secureLocalStorage from "react-secure-storage";

import config, { checkStorage, getCurrency, getStorage } from "../Config";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import { checkSecureLogin, getCustomerId, getSecureStorage, getTokenKey, getTokenValue, setSecureStorage } from "../checkSecureLogin";

export default function Product() {
  const { t, i18n }=useTranslation();
  const [getLang, setLang]=useState('en');
  const [getProdcutDetails, SetProductDetails]=useState({});
  const [getNewPublishedBook, setNewPublishedBook]=useState([]);
  const [getMoreImages, SetMoreImages]=useState([]);
  const [counter, setCounter]=useState(1);
  const [qty, setQty]=useState();
  const navigate=useNavigate();
  const { id }=useParams();

  const [nav1, setNav1]=useState();
  const [nav2, setNav2]=useState();

  useEffect(() => {
    getProductDetails();
    getNewPublishedBookApi();
  }, [id]);

  function getPercent(old_price, new_price) {
    return Math.floor((old_price-new_price)*100/old_price)
  }

  const cart=() => {
    return navigate("/cart")
  }
  const wishlist=() => {
    return navigate("/wishlist")
  }
  function incrementCounter() {
    setCounter(counter+1);
    if (counter==qty) {
      setCounter(qty);
    }
  }
  function decrementCounter() {
    if (counter<=1) {
      return false
    }
    setCounter(counter-1);

  }
  if (counter==qty) {
    incrementCounter=() => setCounter(qty);
  }
  function getProductDetails() {
    var formdata=new FormData();
    formdata.append('product_id', id);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    var final={};
    fetch(`${config.baseUrl}/books/view/detail`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status==='success') {
          final={};
          let name=result.data.detail.name;
          let publisher=result.data.detail.publisher;
          let description=result.data.detail.description;

          let author_name_en=result.data.author.name;
          let author_name_ar=result.data.author.name_ar;

          let author_detail_en=result.data.author.description;
          let author_detail_ar=result.data.author.description_ar;

          let images=result.data.detail.images;
          let tags=result.data.detail.tags;
          let return_policy=result.data.detail.return_policy;
          let abstract_view=result.data.detail.abstract_view;

          final.id=result.data.detail.id;
          final.sku=result.data.detail.sku;
          final.publishing_year=result.data.detail.publishing_year;
          final.qty=result.data.detail.qty;
          final.qty_type=result.data.detail.qty_type;
          final.old_price=result.data.detail.old_price;
          final.new_price=result.data.detail.new_price;
          final.thumb_img=result.data.detail.thumb_img;
          final.thumb_img=result.data.detail.thumb_img;
          final.status=result.data.detail.status;
          final.is_published=result.data.detail.is_published;
          final.category_id=result.data.detail.category_id;
          final.created_at=result.data.detail.created_at;
          final.name_ar=name.ar;
          final.name_en=name.en;
          final.publisher_ar=publisher.ar;
          final.publisher_en=publisher.en;
          final.description_ar=description.ar;
          final.description_en=description.en;
          final.abstract_view_en=abstract_view.en;
          final.abstract_view_ar=abstract_view.ar;
          final.author_name_en=author_name_en;
          final.author_name_ar=author_name_ar;

          final.author_detail_en=author_detail_en;
          final.author_detail_ar=author_detail_ar;

          final.tags_ar=tags.ar;
          final.tags_en=tags.en;
          final.return_policy_ar=return_policy.ar;
          final.return_policy_en=return_policy.en;

          SetProductDetails(final);
          SetMoreImages(images);
          setQty(final.qty);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
        console.log(error);
      }).finally(() => {

      });
  }



  async function addTocart(x, id) {
    x.preventDefault();
    var btn_ele=x.currentTarget;
    var btn_html=btn_ele.innerHTML;

    if (!checkSecureLogin()) {

      let cartdata={
        qty: 1,
        product_id: id
      };

      let currentCart=getSecureStorage('cart')??[];
      const isProductInCart=currentCart.some(item => item.product_id===cartdata.product_id);

      if (!isProductInCart) {
        let newcart=[...currentCart, cartdata];
        setSecureStorage('cart', newcart);
      }

      Toastify({
        text: t('Added To Cart'),
        duration: 3000,
        style: {
          background: config.toastSuccess,
        }
      }).showToast();
      return false;
    }


    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${config.baseUrl}/books/add/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML=btn_html;
      });
  }


  function addToWishlist(x, id) {
    x.preventDefault();

    if (!checkSecureLogin()) {
      Toastify({
        text: t('Please Login'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/setwishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  const getNewPublishedBookApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getpublishedbook`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {

          const newPublishedProductData=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;

            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;
            return final;
          });
          setNewPublishedBook(newPublishedProductData);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong '
        })
      }).finally(() => {

      });
  }

  const slickSettings={
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of slides to show as needed
    slidesToScroll: 2,
  };


  const leftSec={
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    speed: 800,
    draggable: false,
    initialSlide: 0,
  };

  const rightSec={
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    vertical: true,
    speed: 800,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          vertical: false,
          slidesToShow: 4
        }
      }
    ]
  };

  const recentSlider={
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    prevArrow: (
      <button className="slide-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>
    ),
    responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };

  function gotoPage(x, path) {
    x.preventDefault();
    return navigate(path);
  }

  return (
    <div>
      <main className="main-wrapper product-wrapper" style={{ marginTop: "50px" }}>
        {/* Start Shop Area  */}
        <div className="axil-single-product-area axil-section-gap pb--0 bg-color-white">
          <div className="single-product-thumb mb--40">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 mb--40">
                  <div className="row">
                    <div className="col-lg-10 order-lg-2">



                      <Slider {...leftSec} asNavFor={nav2} ref={(slider1) => setNav1(slider1)} className="single-product-thumbnail product-large-thumbnail- axil-product thumbnail-badge zoom-gallery">
                        {
                          getMoreImages?.map((item, index) => {
                            return (
                              <div className="thumbnail" key={index}>
                                <img src={`${config.baseImg}/${item}`} alt="left image" />
                                <div className="label-block label-right">
                                  {
                                    (item.new_price>item.old_price)?
                                      <div className="product-badget">20% OFF</div>
                                      :<></>
                                  }

                                </div>
                                <div className="product-quick-view position-view">
                                  <a href="../assets/images/product/product-big-01.png" className="popup-zoom">
                                    <i className="far fa-search-plus"></i>
                                  </a>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Slider>
                    </div>


                    {/* right image */}
                    <div className="col-lg-2 order-lg-1">

                      <Slider {...rightSec} className="product-small-thumb product-right-sec small-thumb-wrapper" asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
                        {
                          getMoreImages?.map((item, index) => {
                            return (
                              <div className="small-thumb-img" key={index}>
                                <img src={`${config.baseImg}/${item}`} alt="right image" />
                              </div>
                            )
                          })
                        }

                      </Slider>

                    </div>
                    {/* right image end*/}

                  </div>
                </div>
                {
                  <div className="col-lg-5 mb--40">
                    <div className="single-product-content">
                      <div className="inner">
                        <h2 className="product-title">{(getLang=='en-US'||getLang=='en')? getProdcutDetails.name_en:getProdcutDetails.name_ar}</h2>
                        <p>{t('ISBN')}: {getProdcutDetails.sku}</p>

                        <span className="price-amount">{getCurrency()} {getProdcutDetails.new_price}</span>

                        <ul className="product-meta">
                          <li>
                            <i className="fal fa-check" />
                            {(getProdcutDetails.qty>0)? 'In stock':'Out of stock'}
                          </li>
                          <li className="text-black mt-3">
                            <span>{t('Author')}: &nbsp;</span>
                            <span>{(getLang=='en-US'||getLang=='en')? getProdcutDetails.author_name_en:getProdcutDetails.author_name_ar}</span>
                          </li>
                          <li className="text-black ">
                            <span>{t('Publisher')}: &nbsp;</span>
                            <span>{(getLang=='en-US'||getLang=='en')? getProdcutDetails.publisher_en:getProdcutDetails.publisher_ar}</span>
                          </li>

                        </ul>
                        <p className="description">
                          <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? getProdcutDetails.description_en:getProdcutDetails.description_ar }} />
                        </p>
                        <div className="product-variations-wrapper">
                          {/* Start Product Variation  */}
                          <div className="product-variation">
                          </div>
                          {/* End Product Variation  */}
                        </div>
                        {/* Start Product Action Wrapper  */}
                        <div className="product-action-wrapper d-flex-center">
                          {/* Start Quentity Action  */}

                          <div className="pro-qty mr--20">
                            <span className="dec qtybtn" onClick={decrementCounter}>-</span>
                            <input type="text" value={counter} />
                            <span className="inc qtybtn" onClick={incrementCounter}>+</span>
                          </div>
                          {/* End Quentity Action  */}
                          {/* Start Product Action  */}
                          <ul className="product-action d-flex-center mb--0">
                            <li className="add-to-cart">
                              <a
                                href="javascript:void(0);"
                                className="axil-btn btn-bg-primary"
                                onClick={(x) => addTocart(x, getProdcutDetails.id)}
                              >
                                Add to Cart
                              </a>
                            </li>
                            <li className="wishlist">
                              <a
                                href="javascript:void(0);"
                                className="axil-btn wishlist-btn"
                                onClick={(x) => addToWishlist(x, getProdcutDetails.id)}
                              >
                                <i className="far fa-heart" />
                              </a>
                            </li>
                          </ul>
                          {/* End Product Action  */}
                        </div>
                        {/* End Product Action Wrapper  */}
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
          {/* End .single-product-thumb */}
          <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white">
            <div className="container">
              <ul className="nav tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="active" id="abstract-view-tab" data-bs-toggle="tab" href="#abstract-view" role="tab" aria-controls="abstract-view" aria-selected="true">
                    {t('Abstract View')}
                  </a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">
                    {t('Description')}
                  </a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="" id="author-tab" data-bs-toggle="tab" href="#author" role="tab" aria-controls="author" aria-selected="true">
                    {t('Author Detail')}
                  </a>
                </li>

              </ul>


              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="abstract-view" role="tabpanel" aria-labelledby="abstract-view-tab">
                  <div className="product-desc-wrapper">
                    <div className="row">
                      <div className="col-lg-12 mb--30">
                        <div className="single-desc">
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? getProdcutDetails.abstract_view_en:getProdcutDetails.abstract_view_ar }} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="tab-pane fade" id="description" role="tabpanel" aria-labelledby="abstract-view-tab">
                  <div className="product-desc-wrapper">
                    <div className="row">
                      <div className="col-lg-12 mb--30">
                        <div className="single-desc">
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? getProdcutDetails.description_en:getProdcutDetails.description_ar }} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="tab-pane fade" id="author" role="tabpanel" aria-labelledby="abstract-view-tab">
                  <div className="product-desc-wrapper">
                    <div className="row">
                      <div className="col-lg-12 mb--30">
                        <div className="single-desc">
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? getProdcutDetails.author_detail_en:getProdcutDetails.author_detail_ar }} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
          {/* woocommerce-tabs */}
        </div>
        {/* End Shop Area  */}
        {/* Start Recently Viewed Product Area  */}
        <br />
        <br />




        <div className="axil-new-arrivals-product-area bg-color-white axil-section-gap pb--0">
          <div className="container">
            <div className="product-area pb--50">
              <div className="section-title-wrapper">
                <span className="title-highlighter highlighter-primary">
                  <i className="far fa-shopping-basket" />
                  This Week’s
                </span>
                <h2 className="title">{t('new_published_books')}</h2>
              </div>

              <Slider {...recentSlider} className="new-arrivals-product-activation slick-layout-wrapper--30 axil-slick-arrow  arrow-top-slide">
                {
                  getNewPublishedBook?.map((item, index) => {
                    return (
                      <div className="slick-single-layout" key={index}>
                        <div className="axil-product product-style-two">
                          <div className="thumbnail">
                            <a href="javascript:void(0);" onClick={(x) => gotoPage(x, `/product/${item.id}`)}>
                              <img src={`${config.baseImg}/${item.thumb_img}`} alt="Product Images" />
                            </a>
                            <div className="label-block label-right">
                              <div className="product-badget">10% OFF</div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="inner">
                              <h5 className="title"><a href="javascript:void(0);" onClick={(x) => gotoPage(x, `/product/${item.id}`)}>{(getLang=='ar')? item.name_ar:item.name_en}</a></h5>
                              <div className="product-price-variant">
                                {
                                  (item.new_price<item.old_price)?
                                    <span className="price old-price">{getCurrency()+' '+item.old_price}</span>
                                    :<></>
                                }
                                <span className="price current-price">{getCurrency()+' '+item.new_price}</span>
                              </div>
                              <div className="product-hover-action">
                                <ul className="cart-action">
                                  <li className="quickview"><a href="#" data-bs-toggle="modal"
                                    data-bs-target="#quick-view-modal"><i className="far fa-eye"></i></a></li>
                                  <li className="select-option"><a href="javascript:void(0);" onClick={((x) => addTocart(x, item.id))}>Add to Cart</a></li>
                                  <li className="wishlist"><a href="wishlist.html"><i className="far fa-heart"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>


            </div>
          </div>
        </div>



        {/* End Recently Viewed Product Area  */}
      </main>
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="../assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="../assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="../assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="../assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
