import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import { checkSecureLogin, getCustomerId, getTokenKey, getTokenValue, getSecureStorage, setSecureStorage } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import swal from "sweetalert";

export default function Cart() {
  const navigate=useNavigate();
  const checkout=() => {
    return navigate("/checkout")
  }

  const { t, i18n }=useTranslation();
  const [isSticky, setIsSticky]=useState(false);
  const [getLang, setLang]=useState('en');
  const [getCartArr, setCartArr]=useState([]);
  const [getCartTotal, setCartTotal]=useState(0.00);
  const [getCartCount, setCartCount]=useState(0);
  const [counters, setCounters]=useState([]);
  const [getSubtotal, setSubtotal]=useState([]);
  const [getTempCounter, setTempCounter]=useState(0);

  useEffect(() => {
    setLang(i18n.language);
  }, [])

  useEffect(() => {
    getCart();
  }, [getTempCounter])

  useEffect(() => {
    doCartTotal();
  }, [getCartArr])


  const getNoauthCart=() => {
    const cartdata=getSecureStorage('cart');
    if (cartdata&&cartdata.length!=0) {
      var formdata=new FormData();
      formdata.append("cart_data", JSON.stringify(cartdata));
      var requestOptions={
        method: 'POST',
        body: formdata,
      };

      fetch(`${config.baseUrl}/books/get/cart/noauth`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status==='success') {
            var finalcart=[];
            finalcart=(result.data).map((item, index) => {
              let name=[];
              let product_id=item.product_id;
              let cart_id=item.cart_id;
              let cart_qty=item.qty;
              let image='';
              let price='';
              let max_qty=0;
              if (item.product) {
                for (let key in (item.product)) {
                  name=JSON.parse((item.product)['name']);
                  image=(item.product)['thumb_img'];
                  price=(item.product)['new_price'];
                  max_qty=(item.product)['qty'];
                }
              }
              return {
                product_id: product_id,
                cart_id: cart_id,
                cart_qty: cart_qty,
                image: image,
                name: name,
                price: price,
                max_qty: max_qty
              }
            })

            let total=0;

            finalcart.map((item, index) => {
              total+=item.price
            })
            setCartCount(finalcart.length);
            setCartTotal(total);

            setCartArr(finalcart);
          } else {
            swal({
              icon: result.status,
              title: result.msg
            })
          }
        }).catch(error => {
          swal({
            icon: 'error',
            title: 'Something Went Wrong'
          })
        }).finally(() => {

        });
    } else {
      setCartArr([]);
      setCartCount(0);
      setCartTotal(0);
    }

  }

  const getCart=() => {
    if (!checkSecureLogin()) {
      getNoauthCart()
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/get/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          var finalcart=[];
          finalcart=(result.data).map((item, index) => {
            let name=[];
            let product_id=item.product_id;
            let cart_id=item.id;
            let cart_qty=item.qty;
            let image='';
            let price='';
            let max_qty=0;
            if (item.product) {
              for (let key in (item.product)) {
                name=JSON.parse((item.product)['name']);
                image=(item.product)['thumb_img'];
                price=(item.product)['new_price'];
                max_qty=(item.product)['qty'];
              }
            }
            return {
              product_id: product_id,
              cart_id: cart_id,
              cart_qty: cart_qty,
              image: image,
              name: name,
              price: price,
              max_qty: max_qty
            }
          })

          let total=0;

          finalcart.map((item, index) => {
            total+=item.price
          })
          setCartCount(finalcart.length);
          setCartTotal(total);

          setCartArr(finalcart);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {

        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  function deleteItem(x, id, product_id) {
    x.preventDefault();
    var btn_ele=x.currentTarget;
    var btn_html=btn_ele.innerHTML;
    if (!checkSecureLogin()||id==0) {
      const cartdata=getSecureStorage('cart');
      if (cartdata&&cartdata.length!=0) {
        const updatedCart=cartdata.filter(item => item.product_id!==product_id);
        setSecureStorage('cart', updatedCart)
      } else {
        setSecureStorage('cart', []);
        setCartCount(0);
        setCartTotal(0);
      }
      getNoauthCart();
      return false;
    }

    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';


    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("cart_id", id);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/remove/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          getCart();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {

        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML=btn_html;
      });
  }


  const incrementCounter=async (x, maxQty, current_qty, index, cart_id, product_id) => {

    if (!checkSecureLogin()&&cart_id==0) {
      const currentCart=getSecureStorage('cart');

      let newcart=currentCart.map((item, index) => {
        if (item.product_id==product_id) {
          let new_qty=item.qty+1

          if (new_qty<=maxQty) {
            item.qty=new_qty;
            return item;
          }

        }
        return item;
      })

      setSecureStorage('cart', newcart);
      getNoauthCart();
      return false;
    }

    (x.currentTarget).disabled=true;

    const newCounters=[...counters];
    if (newCounters[index]===undefined) {

      newCounters[index]=current_qty+1;
      if ((newCounters[index])>maxQty) {
        return false;
      }
      updateQty(cart_id, newCounters[index]);
      return false;
      setCounters(newCounters);
    } else {
      newCounters[index]=newCounters[index]+1
      if ((newCounters[index]+1)>maxQty) {
        return false;
      }
      await updateQty(cart_id, newCounters[index]);
      setCounters(newCounters);
      (x.currentTarget).disabled=false;
    }
  };

  const decrementCounter=async (x, maxQty, current_qty, index, cart_id, product_id) => {
    if (!checkSecureLogin()&&cart_id==0) {
      const currentCart=getSecureStorage('cart');

      let newcart=currentCart.map((item, index) => {
        if (item.product_id==product_id) {
          if (item.qty>1) {
            let new_qty=item.qty-1
            item.qty=new_qty;

            return item;
          } else {
            return item;
          }
        }
        return item;
      })
      setSecureStorage('cart', newcart);
      getNoauthCart();
      return false;
    }

    (x.currentTarget).disabled=true;
    const newCounters=[...counters];
    if (newCounters[index]===undefined) {
      if ((current_qty-1)<1) {
        return false;
      }
      newCounters[index]=current_qty-1;
      await updateQty(cart_id, newCounters[index]);
      setCounters(newCounters);
    } else {
      if ((newCounters[index]-1)<1) {
        return false;
      }
      newCounters[index]=newCounters[index]-1
      await updateQty(cart_id, newCounters[index]);
      setCounters(newCounters);
    }

  };

  async function updateQty(cart_id, qty) {
    try {
      var myHeaders=new Headers();
      myHeaders.append(getTokenKey(), getTokenValue());
      myHeaders.append('Accept-Language', getLang);

      var formdata=new FormData();
      formdata.append("cart_id", cart_id);
      formdata.append("quantity", qty);
      var requestOptions={
        method: 'POST',
        body: formdata,
        headers: myHeaders,
        redirect: 'follow'
      };

      const response=await fetch(`${config.baseUrl}/books/update/cart`, requestOptions);
      const result=await response.json();

      if (result.status==='success') {
      } else {
        swal({
          icon: result.status,
          title: result.msg
        });
      }
    } catch (error) {
      swal({
        icon: 'error',
        title: 'Something Went Wrong'
      });
    } finally {

    }
  }


  const changeLanguage=(lng) => {

    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    var style_link=document.getElementById('style-type');

    if (currentLanguage=='ar') {
      style_link.href='../assets/css/style-rtl.min.css';
    } else {
      style_link.href='../assets/css/style.min.css';
    }
  };

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }

  function doCartTotal() {
    var currTotal=0;
    getCartArr?.map((item, index) => {
      currTotal+=((item.price)*((counters[index])? counters[index]:item.cart_qty))
    })
    setSubtotal(currTotal);
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Cart Area  */}
        <div className="axil-product-cart-area axil-section-gap" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="axil-product-cart-wrap">
              <div className="product-table-heading">
                <h4 className="title">{t('your_cart')}</h4>
                <a href="#" className="cart-clear">{t('clear_shopping_cart')}</a>
              </div>
              <div className="table-responsive">
                <table className="table axil-product-table axil-cart-table mb--40">
                  <thead>
                    <tr>
                      <th scope="col" className="product-remove" />
                      <th scope="col" className="product-thumbnail">{t('product')}</th>
                      <th scope="col" className="product-title" />
                      <th scope="col" className="product-price">{t('price')}</th>
                      <th scope="col" className="product-quantity">{t('quantity')}</th>
                      <th scope="col" className="product-subtotal">{t('subtotal')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getCartArr?.map((item, index) => {

                        return (

                          <tr>
                            <td className="product-remove"><button onClick={(x) => deleteItem(x, item.cart_id, item.product_id)} className="remove-wishlist"><i className="fal fa-times" /></button></td>
                            <td className="product-thumbnail"><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/'+item.product_id)}><img src={`${config.baseImg}/${item.image}`} alt="Digital Product" /></a></td>
                            <td className="product-title"><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/'+item.product_id)}>{(getLang=='en-US'||getLang=='en')? item.name.en:item.name.ar}</a></td>
                            <td className="product-price" data-title="Price"><span className="currency-symbol">{getCurrency()} </span>{toDecimal(item.price)}</td>
                            <td className="product-quantity" data-title="Qty">

                              <div className="pro-qty">
                                <button className="dec qtybtn" onClick={(x) => decrementCounter(x, item.max_qty, item.cart_qty, index, item.cart_id, item.product_id)}>-</button>
                                <input type="number" value={(counters[index])? counters[index]:item.cart_qty} />
                                <button className="inc qtybtn" onClick={(x) => incrementCounter(x, item.max_qty, item.cart_qty, index, item.cart_id, item.product_id)}>+</button>
                              </div>


                            </td>
                            <td className="product-subtotal" data-title="Subtotal"><span className="currency-symbol">{getCurrency()} </span>{(item.price)*((counters[index])? counters[index]:item.cart_qty)}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
              <div className="cart-update-btn-area">
                <div className="input-group product-cupon">
                  <input placeholder="Enter coupon code" type="text" />
                  <div className="product-cupon-btn">
                    <button type="submit" className="axil-btn btn-outline">{t('apply')}</button>
                  </div>
                </div>
                {/* <div className="update-btn">
                    <a href="#" className="axil-btn btn-outline">{t('update_cart')}</a>
                  </div> */}
              </div>
              <div className="row">
                <div className="col-xl-5 col-lg-7 offset-xl-7 offset-lg-5">
                  <div className="axil-order-summery mt--80">
                    <h5 className="title mb--20">{t('order_summary')}</h5>
                    <div className="summery-table-wrap">
                      <table className="table summery-table mb--30">
                        <tbody>
                          <tr className="order-subtotal">
                            <td>{t('subtotal')}</td>
                            <td>{getCurrency()} {toDecimal(getSubtotal)}</td>
                          </tr>
                          <tr className="order-shipping">
                            <td>{t('shipping')}</td>
                            <td>
                              <div className="input-group">
                                <input type="radio" id="radio1" name="shipping" defaultChecked />
                                <label checked htmlFor="radio1">Free Shippping</label>
                              </div>

                            </td>
                          </tr>

                          <tr className="order-total">
                            <td>{t('total')}</td>
                            <td className="order-total-amount">{getCurrency()} {toDecimal(getSubtotal)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <a href="" className="axil-btn btn-bg-primary checkout-btn" onClick={checkout}>Process to Checkout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Cart Area  */}
      </main>
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
