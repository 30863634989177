import React from "react";
import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, json, useParams, Link, useNavigate, useLocation } from "react-router-dom";
import diomond from "../../Assets/images/author1.png";
import elec1 from "../../Assets/images/elec-1.png";
import elec2 from "../../Assets/images/elec-2.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import config, { checkStorage, getCurrency, getStorage } from "../Config";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import swal from "sweetalert";
import i18n from 'i18next';
import "./home.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import secureLocalStorage from "react-secure-storage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../../Assets/images/blog/blog-01.png";
import { checkSecureLogin, getCustomerId, getSecureStorage, getTokenKey, getTokenValue, setSecureStorage } from "../checkSecureLogin";
import sal from 'sal.js';
import 'sal.js/dist/sal.css'; // Import the CSS for styles

export default function Home() {
  const { t, i18n }=useTranslation();
  const [nav1, setNav1]=useState();
  const [nav2, setNav2]=useState();
  const [getBanner, setBanner]=useState([]);
  const [getCategory, setCategory]=useState([]);
  const [getLang, setLang]=useState('en');

  const [getExploreProduct, setExploreProduct]=useState([]);
  const [getExplorePage, setExplorePage]=useState(1);
  const [getExploreShowMoreBtn, setExploreShowMoreBtn]=useState(false);

  const [getNewPublishedBook, setNewPublishedBook]=useState([]);

  const [getMostSoldBook, setMostSoldBook]=useState([]);
  const [getMostSoldPage, setMostSoldPage]=useState(1);
  const [getMostSoldShowMoreBtn, setMostSoldShowMoreBtn]=useState(false);


  const [getProdcut, SetProduct]=useState([]);
  const ref=useRef();
  const [showButton, setShowButton]=useState(false);
  const navigate=useNavigate();
  const { pathname }=useLocation();


  useEffect(() => {

    setLang(i18n.language);
    
    function handleScroll() {
      if (window.scrollY>300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getBannerApi();
    getCategoryApi();
    getNewPublishedBookApi();
    function handleScroll() {
      if (window.scrollY>300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getExploreProductApi();
  }, [getExplorePage])

  useEffect(() => {
    getMostSoldBookApi();
  }, [getMostSoldPage])


  function showDetail(a) {
    localStorage.setItem('id', a);
    return navigate('/product');
  }

  function getBannerApi() {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/common/gethomebanner`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status==='success') {
          setBanner(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  function getCategoryApi() {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    var catData=[];
    fetch(`${config.baseUrl}/common/getallbookcategory`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          (result.data).map((data, index) => {
            let name=JSON.parse(data.name);
            name.id=data.id;
            name.image=data.image;
            catData.push(name);
          })
          setCategory(catData);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }
  function getPercent(old_price, new_price) {
    return Math.floor((old_price-new_price)*100/old_price)
  }
  const getExploreProductApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getexplorebook?page=${getExplorePage}`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          if ((result.data.data).length===0) {
            setExploreShowMoreBtn(false);
          } else {
            setExploreShowMoreBtn(true);
          }
          const newExploreProductData=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;
            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;

            return final;
          });
          setExploreProduct(prevExploreProduct => [...prevExploreProduct, ...newExploreProductData]);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }


  const getNewPublishedBookApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getnewpublishedbook`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {

          const newPublishedProductData=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;


            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;
            return final;
          });
          setNewPublishedBook(newPublishedProductData);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong '
        })
      }).finally(() => {

      });
  }

  const getMostSoldBookApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getmostsoldbook?page=${getMostSoldPage}`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          if ((result.data.data).length===0) {
            setMostSoldShowMoreBtn(false);
          } else {
            setMostSoldShowMoreBtn(true);
          }
          const newMostSoldBook=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;


            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;

            return final;
          });
          setMostSoldBook(prevMostSoldBook => [...prevMostSoldBook, ...newMostSoldBook]);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  const scrollToTop=() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');
    if (currentLanguage=='ar') {
      //currDirEle.setAttribute('dir', 'rtl');
    } else {
      //currDirEle.setAttribute('dir', 'ltr');
    }
  };

  const sliderSettings={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    autoplay: false,
    slidesToScroll: 7,
    arrows: true,
    prevArrow: (
      <button className="slide-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ]
  };

  const sliderJournal={
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    autoplay: false,
    slidesToScroll: 6,
    arrows: true,
    prevArrow: (
      <button className="slide-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ]
  };


  const bannerLeftSec={
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    focusOnSelect: false,
    speed: 500,
    fade: true,
    autoplay: false,

  };

  const bannerRightSec={
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    focusOnSelect: false,
    speed: 1000,
    rtl: ((getLang=='en-US'||getLang=='en'))? false:true,
    autoplay: false,
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      }
    }
    ]
  };


  const newPublishedBookSetting={
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    prevArrow: (
      <button className="slide-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>
    ),
    responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };

  const exploreOutProductSlider={
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    prevArrow: (
      <button className="slide-arrow prev-arrow"><i className="fal fa-long-arrow-left"></i></button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow"><i className="fal fa-long-arrow-right"></i></button>
    ),
    responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  };

  function exploreLoadMore(x) {
    x.preventDefault();
    let final=getExplorePage+1;
    setExplorePage(final);
  }

  function mostSoldMoreBtn(x) {
    x.preventDefault();
    let final=getMostSoldPage+1;
    setMostSoldPage(final);
  }

  function gotoPage(x, path) {
    x.preventDefault();
    return navigate(path);
  }

  async function addTocart(x, id) {
    x.preventDefault();
    var btn_ele=x.currentTarget;
    var btn_html=btn_ele.innerHTML;

    if (!checkSecureLogin()) {

      let cartdata={
        qty: 1,
        product_id: id
      };

      let currentCart=getSecureStorage('cart')??[];
      const isProductInCart=currentCart.some(item => item.product_id===cartdata.product_id);

      if (!isProductInCart) {
        let newcart=[...currentCart, cartdata];
        setSecureStorage('cart', newcart);
      }

      Toastify({
        text: t('Added To Cart'),
        duration: 3000,
        style: {
          background: config.toastSuccess,
        }
      }).showToast();
      return false;
    }


    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${config.baseUrl}/books/add/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML=btn_html;
      });
  }


  function addToWishlist(x, id) {
    x.preventDefault();

    var btn_ele=x.currentTarget;
    var btn_html=btn_ele.innerHTML;

    if (!checkSecureLogin()) {
      Toastify({
        text: t('Please Login'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/setwishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.setAttribute('disabled', false);
        btn_ele.innerHTML=btn_html;
      });
  }

  return (
    <div>

      {showButton&&(
        <a href="#top" className="back-to-top show" id="backto-top"><i className="fal fa-arrow-up"></i></a>
      )}

      <main className="main-wrapper">
        <div className="axil-main-slider-area main-slider-style-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-sm-6 bannerleftsec">
                <div className="main-slider-content">
                  <div class="main-slider-content">
                    <div className="">
                      <Slider {...bannerLeftSec} className="slider-content-activation-one" asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                        {
                          getBanner?.map((product, index) => {
                            return (
                              <div className="single-slide slick-slide" key={index}>
                                <span className="subtitle"><i className="fas fa-fire"></i>{product.subtitle??''}</span>
                                <h1 className="title">{product.text}</h1>
                                <div className="slide-action">
                                  <div className="shop-btn">
                                    <a href={product.link} className="axil-btn btn-bg-white"><i className="fal fa-shopping-cart"></i>{t('Get It')}</a>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </Slider>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-sm-6">
                <div className="main-slider-large-thumb">
                  <div className="">
                    <Slider {...bannerRightSec} className="axil-slick-dots slider-thumb-activation-one" asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
                      {
                        getBanner?.map((product, index) => (
                          <div className="single-slide slick-slide">
                            <img className="right-banner-img" src={`${config.baseImg}/${product.data}`} alt="Product" />
                            <div className="product-price">
                              <span className="text">From</span>
                              <span className="price-amount">$49.00</span>
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <ul className="shape-group">
            <li className="shape-1">
              <img src="assets/images/others/shape-1.png" alt="Shape" />
            </li>
            <li className="shape-2">
              <img src="assets/images/others/shape-2.png" alt="Shape" />
            </li>
          </ul>
        </div>

        {/* journals section*/}


        <div className="axil-categorie-area bg-color-white axil-section-gapcommon">
          <div className="container">
            <div className="section-title-wrapper">
              <span className="title-highlighter highlighter-secondary">
                {" "}
                <i className="far fa-tags" /> {t('Important Links')}
              </span>
              <h2 className="title">Explore Other Pages</h2>
            </div>

            <Slider {...sliderJournal} className="slick-layout-wrapper--15 axil-slick-arrow  arrow-top-slide">
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="javascript:void(0);" onClick={(x) => gotoPage(x, '/journals')}>
                    <img className="img-fluid" src="./assets/images/Journal/1.png" alt="product categorie"
                    />
                    <h6 className="cat-title">Journals</h6>
                  </a>
                </div>
              </div>
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="javascript:void(0);" onClick={(x) => gotoPage(x, '/shop')}>
                    <img
                      className="img-fluid"
                      src="./assets/images/Journal/2.png"
                      alt="product categorie"
                    />
                    <h6 className="cat-title">Books</h6>
                  </a>
                </div>
              </div>
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="./assets/images/Journal/3.png"
                      alt="product categorie"
                    />
                    <h6 className="cat-title">Publishing Forms</h6>
                  </a>
                </div>
              </div>
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="./assets/images/Journal/4.png"
                      alt="product categorie"
                    />
                    <h6 className="cat-title">Staff</h6>
                  </a>
                </div>
              </div>
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="./assets/images/Journal/5.png"
                      alt="product categorie"
                    />
                    <h6 className="cat-title">Albadgeer</h6>
                  </a>
                </div>
              </div>
              <div className="slick-single-layoutd">
                <div className="categrie-product">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="./assets/images/Journal/6.png"
                      alt="product categorie"
                    />
                    <h6 className="cat-title">eStore</h6>
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        {/* Start Categorie Area  */}

        <div className="axil-categorie-area bg-color-white">
          <div className="container">
            <div className="section-title-wrapper">
              <span className="title-highlighter highlighter-secondary">
                {" "}
                <i className="far fa-tags" /> Categories
              </span>
              <h2 className="title">{t('browse_by_category')}</h2>
            </div>
            <Slider {...sliderSettings} className="slick-layout-wrapper--15 axil-slick-arrow aspect-ratio  arrow-top-slide">
              {
                getCategory&&getCategory.map((item, index) => {
                  return (
                    <div className="slick-single-layoutd" onClick={(x) => gotoPage(x, `/shop/${item.id}`)}>
                      <div className="categrie-product">
                        <a href="javascript:void(0);">
                          <img
                            className="img-fluid"
                            src={`${config.baseImg}/${item.image}`}
                            alt="product categorie"
                          />
                          <h6 className="cat-title">{(getLang=='en-US'||getLang=='en')? item.en:item.ar}</h6>
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </div>

        {/* <div className="axil-poster-countdown">
          <div className="container">
            <div className="poster-countdown-wrap bg-lighter">
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="poster-countdown-content">
                    <div className="section-title-wrapper">
                      <span className="title-highlighter highlighter-secondary">
                        {" "}
                        <i className="fal fa-headphones-alt" /> Don’t Miss!!
                      </span>
                      <h2 className="title">Limited Time Offer Buy Now</h2>
                    </div>
                    <div className="poster-countdown countdown mb--40" />
                    <a href="javascript:void(0);" className="axil-btn btn-bg-primary">
                      Check it Out!
                    </a>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6">
                  <div className="poster-countdown-thumbnail">
                    <img
                      src="./assets/images/product/poster/poster-03.png"
                      alt="Poster Product"
                    />
                    <div className="music-singnal">
                      <div className="item-circle circle-1" />
                      <div className="item-circle circle-2" />
                      <div className="item-circle circle-3" />
                      <div className="item-circle circle-4" />
                      <div className="item-circle circle-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Poster Countdown Area  */}

        {/* Start Expolre Product Area  */}
        <br />
        <br />
        <br />
        <div className="axil-new-arrivals-product-area bg-color-white axil-section-gap pb--0">
          <div className="container">
            <div className="product-area pb--50">
              <div className="section-title-wrapper">
                <span className="title-highlighter highlighter-primary">
                  <i className="far fa-shopping-basket" />
                  {t('books')}
                </span>
                <h2 className="title">{t('Explore Our Journals')}</h2>
              </div>

              <Slider {...exploreOutProductSlider} className="new-arrivals-product-activation slick-layout-wrapper--30 axil-slick-arrow  arrow-top-slide">
                {
                  getExploreProduct?.map((item, index) => {
                    return (
                      <div className="slick-single-layout" key={index}>
                        <div className="axil-product product-style-one">
                          <div className="thumbnail">
                            <a href="javascript:void(0);" onClick={(x) => gotoPage(x, `product/${item.id}`)}>
                              <img src={`${config.baseImg}/${item.thumb_img}`} alt="Product Images" />
                            </a>
                            <div className="label-block label-right d-none">
                              <div className="product-badget">10% OFF</div>
                            </div>


                            <div className="product-hover-action">
                              <ul className="cart-action">
                                <li className="quickview"><a href="#" data-bs-toggle="modal"
                                  data-bs-target="#quick-view-modal"><i className="far fa-eye"></i></a></li>
                                <li className="select-option"><a href="javascript:void(0);" onClick={((x) => addTocart(x, item.id))}>Add to Cart</a></li>
                                <li className="wishlist"><a href="javascript:void(0);" onClick={(x) => addToWishlist(x, item.id)}><i className="far fa-heart"></i></a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="inner">
                              <h5 className="title"><a href="javascript:void(0);" onClick={(x) => gotoPage(x, `product/${item.id}`)}>{(getLang=='ar')? item.name_ar:item.name_en}</a></h5>
                              <div className="product-price-variant">
                                {
                                  (item.new_price<item.old_price)?
                                    <span className="price old-price">{getCurrency()+' '+item.old_price}</span>
                                    :<></>
                                }
                                <span className="price current-price">{getCurrency()+' '+item.new_price}</span>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>


            </div>
          </div>
        </div>
        {/* End Expolre Product Area  */}

        {/* Start New Arrivals Product Area  */}
        <div className="axil-new-arrivals-product-area bg-color-white axil-section-gap pb--0">
          <div className="container">
            <div className="product-area pb--50">
              <div className="section-title-wrapper">
                <span className="title-highlighter highlighter-primary">
                  <i className="far fa-shopping-basket" />
                  {t('books')}
                </span>
                <h2 className="title">{t('Explore Published Books')}</h2>
              </div>

              <Slider {...newPublishedBookSetting} className="new-arrivals-product-activation slick-layout-wrapper--30 axil-slick-arrow  arrow-top-slide">
                {
                  getNewPublishedBook?.map((item, index) => {
                    return (
                      <div className="slick-single-layout" key={index}>
                        <div className="axil-product product-style-one">
                          <div className="thumbnail">
                            <a href="javascript:void(0);" onClick={(x) => gotoPage(x, `product/${item.id}`)}>
                              <img src={`${config.baseImg}/${item.thumb_img}`} alt="Product Images" />
                            </a>
                            <div className="label-block label-right d-none">
                              <div className="product-badget">10% OFF</div>
                            </div>


                            <div className="product-hover-action">
                              <ul className="cart-action">
                                <li className="quickview"><a href="#" data-bs-toggle="modal"
                                  data-bs-target="#quick-view-modal"><i className="far fa-eye"></i></a></li>
                                <li className="select-option"><a href="javascript:void(0);" onClick={((x) => addTocart(x, item.id))}>Add to Cart</a></li>
                                <li className="wishlist"><a href="javascript:void(0);" onClick={(x) => addToWishlist(x, item.id)}><i className="far fa-heart"></i></a></li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="inner">
                              <h5 className="title"><a href="javascript:void(0);" onClick={(x) => gotoPage(x, `product/${item.id}`)}>{(getLang=='ar')? item.name_ar:item.name_en}</a></h5>
                              <div className="product-price-variant">
                                {
                                  (item.new_price<item.old_price)?
                                    <span className="price old-price">{getCurrency()+' '+item.old_price}</span>
                                    :<></>
                                }
                                <span className="price current-price">{getCurrency()+' '+item.new_price}</span>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>


            </div>
          </div>
        </div>
        {/* End New Arrivals Product Area  */}
        {/* Start Most Sold Product Area  */}
        <br />
        <br />
        {/* <div className="axil-most-sold-product axil-section-gap">
          <div className="container">
            <div className="product-area pb--50">
              <div className="section-title-wrapper section-title-center">
                <span className="title-highlighter highlighter-primary">
                  <i className="fas fa-star" /> Most Sold
                </span>
                <h2 className="title">{t('most_sold_books')}</h2>
              </div>
              <div className="row row-cols-xl-2 row-cols-1 row--15">
                {
                  getMostSoldBook?.map((item, index) => {
                    return (
                      <div className="col" key={index}>
                        <div className="axil-product-list most-sold-book">
                          <div className="thumbnail">
                            <a href="javascript:void(0);" onClick={(x) => gotoPage(x, `/product/${item.id}`)}>
                              <img src={`${config.baseImg}/${item.thumb_img}`} alt="Yantiti Leather Bags" />
                            </a>
                          </div>
                          <div className="product-content">

                            <h6 className="product-title">
                              <a href="javascript:void(0);" onClick={(x) => gotoPage(x, `/product/${item.id}`)}>{(getLang=='ar')? item.name_ar:item.name_en}</a>
                            </h6>
                            <div className="product-price-variant">
                              <span className="price current-price">{getCurrency()+' '+item.new_price}</span>
                              <span className="price old-price">{getCurrency()+' '+item.old_price}</span>
                            </div>
                            <div className="product-cart">
                              <a href={`product/${item.id}`} onClick={(x) => addTocart(x, item.id)} className="cart-btn">
                                <i className="fal fa-shopping-cart" />
                              </a>
                              <a href="javascript:void(0);" onClick={(x) => addToWishlist(x, item.id)} className="cart-btn">
                                <i className="fal fa-heart" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }


              </div>

              {
                (getMostSoldShowMoreBtn)?
                  <div className="row">
                    <div className="col-lg-12 text-center mt--20 mt_sm--0">
                      <a href="javascript:void(0);" onClick={(x) => mostSoldMoreBtn(x)} className="axil-btn btn-bg-lighter btn-load-more">
                        {t('Show More')}
                      </a>
                    </div>
                  </div>:<></>
              }

            </div>
          </div>
        </div> */}
        {/* End Most Sold Product Area  */}
        {/* Start Why Choose Area  */}
        <div className="axil-why-choose-area pb--50 pb_sm--30" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="section-title-wrapper section-title-center">
              <span className="title-highlighter highlighter-secondary">
                <i className="fal fa-thumbs-up" />
                {t('Why Us')}
              </span>
              <h2 className="title">{t('why_qatar_university_press')}</h2>
            </div>
            <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 row--20">
              <div className="col">
                <div className="service-box">
                  <div className="icon">
                    <img
                      src="./assets/images/icons/service6.png"
                      alt="Service"
                    />
                  </div>
                  <h6 className="title">{t('fast_and_secure_delivery')}</h6>
                </div>
              </div>
              <div className="col">
                <div className="service-box">
                  <div className="icon">
                    <img src="./assets/images/icons/service7.png" alt="Service" />
                  </div>
                  <h6 className="title">{t('100%_guarantee_On_Product')}</h6>
                </div>
              </div>
              <div className="col">
                <div className="service-box">
                  <div className="icon">
                    <img src="./assets/images/icons/service8.png" alt="Service" />
                  </div>
                  <h6 className="title">{t('24_hour_return_policy')}</h6>
                </div>
              </div>
              <div className="col">
                <div className="service-box">
                  <div className="icon">
                    <img
                      src="./assets/images/icons/service9.png"
                      alt="Service"
                    />
                  </div>
                  <h6 className="title">{t('24_hour_return_policy')}</h6>
                </div>
              </div>
              <div className="col">
                <div className="service-box">
                  <div className="icon">
                    <img
                      src="./assets/images/icons/service10.png"
                      alt="Service"
                    />
                  </div>
                  <h6 className="title">{t('next_level_pro_quality')}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      {/* <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div div className="modal fade quick-view-product" id="quick-view-modal" hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="far fa-times" /></button>
            </div>
            <div className="modal-body">
              <div className="single-product-thumb">
                <div className="row">
                  <div className="col-lg-7 mb--40">
                    <div className="row">
                      <div className="col-lg-10 order-lg-2">
                        <div className="single-product-thumbnail product-large-thumbnail axil-product thumbnail-badge zoom-gallery">
                          <div className="thumbnail">
                            <img src="assets/images/product/product-big-01.png" alt="Product Images" />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a href="assets/images/product/product-big-01.png" className="popup-zoom">
                                <i className="far fa-search-plus" />
                              </a>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img src="assets/images/product/product-big-02.png" alt="Product Images" />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a href="assets/images/product/product-big-02.png" className="popup-zoom">
                                <i className="far fa-search-plus" />
                              </a>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <img src="assets/images/product/product-big-03.png" alt="Product Images" />
                            <div className="label-block label-right">
                              <div className="product-badget">20% OFF</div>
                            </div>
                            <div className="product-quick-view position-view">
                              <a href="assets/images/product/product-big-03.png" className="popup-zoom">
                                <i className="far fa-search-plus" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 order-lg-1">
                        <div className="product-small-thumb small-thumb-wrapper">
                          <div className="small-thumb-img">
                            <img src="assets/images/product/product-thumb/thumb-08.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="assets/images/product/product-thumb/thumb-07.png" alt="thumb image" />
                          </div>
                          <div className="small-thumb-img">
                            <img src="assets/images/product/product-thumb/thumb-09.png" alt="thumb image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mb--40">
                    <div className="single-product-content">
                      <div className="inner">
                        <h3 className="product-title">Serif Coffee Table</h3>
                        <span className="price-amount">$155.00 - $255.00</span>
                        <ul className="product-meta">
                          <li><i className="fal fa-check" />In stock</li>
                          <li><i className="fal fa-check" />Free delivery available</li>
                          <li><i className="fal fa-check" />Sales 30% Off Use Code: MOTIVE30</li>
                        </ul>
                        <p className="description">In ornare lorem ut est dapibus, ut tincidunt nisi pretium. Integer ante est, elementum eget magna. Pellentesque sagittis dictum libero, eu dignissim tellus.</p>
                        <div className="product-variations-wrapper">
                          {/* Start Product Variation  */}
                          <div className="product-variation">
                            <h6 className="title">Colors:</h6>
                            <div className="color-variant-wrapper">
                              <ul className="color-variant mt--0">
                                <li className="color-extra-01 active"><span><span className="color" /></span>
                                </li>
                                <li className="color-extra-02"><span><span className="color" /></span>
                                </li>
                                <li className="color-extra-03"><span><span className="color" /></span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* End Product Variation  */}
                          {/* Start Product Variation  */}

                          {/* End Product Variation  */}
                        </div>
                        {/* Start Product Action Wrapper  */}
                        <div className="product-action-wrapper d-flex-center">
                          {/* Start Quentity Action  */}
                          <div className="pro-qty"><input type="text" defaultValue={1} /></div>
                          {/* End Quentity Action  */}
                          {/* Start Product Action  */}
                          <ul className="product-action d-flex-center mb--0">
                            <li className="add-to-cart"><a href="javascript:void(0);" className="axil-btn btn-bg-primary">Add to Cart</a></li>
                            <li className="wishlist"><a href="javascript:void(0);" className="axil-btn wishlist-btn"><i className="far fa-heart" /></a></li>
                          </ul>
                          {/* End Product Action  */}
                        </div>
                        {/* End Product Action Wrapper  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
