import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import config from "../Config";
import i18n from 'i18next';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function NewsList() {
  const { t, i18n }=useTranslation();
  const [getLang, setLang]=useState('en');
  const [getNews, setNews]=useState([]);
  const searchRef=useRef();
  const searchBtnRef=useRef();
  const navigate=useNavigate();
  const [show, setShow]=useState(false);
  const [getNewsDetail, setNewsDetail]=useState({});
  const [currentPage, setCurrentPage]=useState(1);
  const [getShowMoreBtn, setShowMoreBtn]=useState(false);

  useEffect(() => {
    setLang(i18n.language);
  }, []);

  useEffect(() => {
    getNewsApi();
  }, [currentPage])


  const handleClose=() => {
    setNewsDetail({});
    setShow(false)
  };


  function handleShow(x, id) {
    x.preventDefault();

    var formdata=new FormData();
    formdata.append('id', id);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/get/news/detail`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setNewsDetail(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {
      });

    setShow(true);
  }

  function getNewsApi() {
    let searchVal=(searchRef.current.value).trim();
    var formdata=new FormData();
    formdata.append('search', searchVal);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    var newsData=[];
    var final={};
    fetch(`${config.baseUrl}/get/news?page=${currentPage}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {

          if ((result.data.data).length===0) {
            setShowMoreBtn(false);
          } else {
            setShowMoreBtn(true);
          }

          (result.data.data).map((data, index) => {
            final={};
            final.id=data.id;
            final.title=data.title;
            final.ar_title=data.ar_title;
            final.description=data.description;
            final.ar_description=data.ar_description;
            final.thumb_img=data.thumb_img;
            final.banner_img=data.banner_img;
            final.keywords=data.keywords;
            final.news_date=data.news_date;
            newsData.push(final);
          })
          setNews(prevExploreProduct => [...prevExploreProduct, ...newsData]);

        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }


  function limitWord(htmlText) {
    let wordLimit=50;
    let textWithoutTags=htmlText.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words=textWithoutTags.split(/\s+/); // Split text into words

    if (words.length>wordLimit) {
      const limitedWords=words.slice(0, wordLimit);
      const limitedText=limitedWords.join(' ')+(words.length>wordLimit? '...':'');
      const limitedHTML=htmlText.replace(textWithoutTags, limitedText);
      return limitedHTML;
    } else {
      return htmlText;
    }
  }


  const loadMore=() => {
    setCurrentPage(currentPage+1);
  }

  function searchBtn(x) {
    x.preventDefault();
    setNews([]);
    getNewsApi();
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    {/* <li className="axil-breadcrumb-item"><a href="" onClick={(x) => navigateTo(x, '/')}>{t('home')}</a></li> */}
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('News List')}</li>
                  </ul>
                  <h1 className="title">{t('News List')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="assets/images/product/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrumb Area  */}
        {/* Start Blog Area  */}
        <div className="axil-blog-area axil-section-gap">

          <div className="container" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div className="axil-single-widget mt--40 widget_search">
              <div className="row">
                <div className="col-md-8">
                  <form class="singin-form"><div class="form-group mb-0">
                    <label>{t('Search')}</label>
                    <input type="text" ref={searchRef} class="form-control" name="name" />
                  </div>
                  </form>
                </div>
                <div className="col-md-4">
                  <button onClick={(x) => searchBtn(x)} ref={searchBtnRef} style={{ width: "auto", padding: "10px 38px" }} class="axil-btn btn-bg-primary btn-sm">Search</button>
                </div>
              </div>

            </div>
          </div>

          <div className="container">
            <div className="row row--25">
              <div className="col-lg-12 axil-post-wrapper">
                {
                  getNews&&getNews.map((item, index) => {
                    console.log(item);
                    return (
                      <div className="content-blog mb--30 aspect-ratio-news-list">
                        <div className="inner">
                          <div className="thumbnail">
                            <a href="javascript:void(0);">
                              <img src={`${config.baseImg}/${item.banner_img}`} alt="Blog Images" />
                            </a>
                          </div>
                          <div className="content">
                            <h4 className="title"><a href="javascript:void(0);">{(getLang=='en')? item.title:item.ar_title}</a></h4>
                            {(getLang=='en')? item.en:item.ar}
                            {/* <div className="axil-post-meta">
                              <div className="post-author-avatar">
                                <img src={`${config.baseImg}/${item.thumb_img}`} alt="Author Images" />
                              </div>
                              <div className="post-meta-content">
                                <h6 className="author-title">
                                  <a href="#">Theresa Underwood</a>
                                </h6>
                                <ul className="post-meta-list">
                                  <li>{item.news_date}</li>                        
                                </ul>
                              </div>
                            </div> */}
                            <div
                              dangerouslySetInnerHTML={{ __html: limitWord((getLang=='en')? item.description:item.ar_description) }}
                            />

                            <div className="read-more-btn">
                              <a className="axil-btn btn-bg-primary" href="javascript:void(0);" onClick={(x) => handleShow(x, item.id)}>Read More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                {/* Start Single Blog  */}
              </div>

            </div>

            {
              (getShowMoreBtn)?
                <div class="row">
                  <div class="col-lg-12 text-center mt--20 mt_sm--0">
                    <a href="javascript:void(0);" class="axil-btn btn-bg-lighter btn-load-more" onClick={loadMore}>{t('Show More')}</a>
                  </div>
                </div>:<></>
            }


            {/* <div className="post-pagination">
              <nav className="navigation pagination" aria-label="Products">
                <ul className="page-numbers">
                  <li><span aria-current="page" className="page-numbers current">1</span></li>
                  <li><a className="page-numbers" href="#">2</a></li>
                  <li><a className="page-numbers" href="#">3</a></li>
                  <li><a className="page-numbers" href="#">4</a></li>
                  <li><a className="page-numbers" href="#">5</a></li>
                  <li><a className="next page-numbers" href="#"><i className="fal fa-arrow-right" /></a></li>
                </ul>
              </nav>
            </div> */}
            {/* End post-pagination */}
          </div>
          {/* End .container */}
        </div>
        {/* End Blog Area  */}
      </main>





      <Modal className="author-modal" show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">{(getLang=='en-US'||getLang=='en')? (getNewsDetail.title):(getNewsDetail.ar_title)}</Modal.Title>
          <button onClick={handleClose} className="modal-custom-close"><i className="fas fa-times"></i></button>
        </Modal.Header>
        <Modal.Body>

          <div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <img className="modal-img" src={`${config.baseImg}/${getNewsDetail.thumb_img}`} />
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? (getNewsDetail.description):(getNewsDetail.ar_description) }} />
                  </p>

                </div>

              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>


      <div className="service-area service-area-news-list">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20 seperator-top">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
