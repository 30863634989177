import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

import swal from "sweetalert";
import config, { getCurrency } from "../Config";
import { checkSecureLogin, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import './contact.css';

export default function Cart() {
  const { t, i18n }=useTranslation();
  const [getContact, setContact]=useState({});
  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };


  useEffect(() => {
    getContactApi();
  }, [])

  const getContactApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/common/getcontact`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          console.log(result.status);
          setContact(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('contact')}</li>
                  </ul>
                  <h1 className="title">{t('contact_with_us')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="assets/images/product/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrumb Area  */}
        {/* Start Contact Area  */}
        <div className="axil-contact-page-area axil-section-gap">
          <div className="container">
            <br />
            <br />
            <br />
            <div className="axil-contact-page">
              <div className="row row--30">
                <div className="col-lg-8">
                  <div className="contact-form">
                    <h3 className="title mb--10">{t('we_would_love_to_hear_from_you.')}</h3>
                    <p>If you’ve got great products your making or looking to work with us then drop us a line.</p>
                    <form id="contact-form" method="POST" action="mail.php" className="axil-contact-form">
                      <div className="row row--10">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-name">{t('name')} <span>*</span></label>
                            <input type="text" name="contact-name" id="contact-name" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-phone">{t('phone')} <span>*</span></label>
                            <input type="text" name="contact-phone" id="contact-phone" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-email">{t('e-mail')} <span>*</span></label>
                            <input type="email" name="contact-email" id="contact-email" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="contact-message">{t('your_message')}</label>
                            <textarea name="contact-message" id="contact-message" cols={1} rows={2} defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group mb--0">
                            <button name="submit" type="button" className="axil-btn btn-bg-primary">{t('Send Message')}</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-location mb--40">
                    <h4 className="title mb--20">{t('Contact Us')}</h4>
                    <span className="address mb--20"><div dangerouslySetInnerHTML={{ __html: getContact.address }} /></span>

                    <ul className="contact-list">
                      <li><i className="fal fa-phone-alt" /> {t('Phone')}: {getContact.phone}</li>
                      <li><i className="fal fa-mobile-alt" /> {t('Mobile')}: {getContact.mobile}</li>
                      <li><i className="fal fa-envelope-open" /> {t('Email')}: {getContact.email}</li>
                    </ul>

                  </div>
                  {/* <div className="contact-career mb--40">
                    <h4 className="title mb--20">Careers</h4>
                    <p>Instead of buying six things, one that you really like.</p>
                  </div> */}
                  {/* <div className="opening-hour">
                    <h4 className="title mb--20">Opening Hours:</h4>
                    <p>Monday to Saturday: 9am - 10pm
                      <br /> Sundays: 10am - 6pm
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            {/* Start Google Map Area  */}
            <br />
            <br />
            <br />
            <div className="axil-google-map-wrap axil-section-gap pb--0">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe width={1080} height={500} id="gmap_canvas" src={getContact.map} />
                </div>
              </div>
            </div>
            {/* End Google Map Area  */}
          </div>
        </div>
        {/* End Contact Area  */}
      </main>
      <br />
      <br />
      <br />
      <div className="service-area ">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20 seperator-top">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}