import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import './catalogue.css';

import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css";
import { Button, Modal } from 'react-bootstrap';

export default function Catalouge() {
  const { t, i18n }=useTranslation();
  const [getLang, setLang]=useState('en');
  const [getJournal, setJournal]=useState([]);
  const [getAuthor, setAuthor]=useState([]);
  const [getAuthorDetail, setAuthorDetail]=useState({});
  const [getCategory, setCategory]=useState([]);
  const nameRef=useRef();
  const categoryRef=useRef();
  const [getCatalogue, setCatalogue]=useState([]);
  const [getCatalogueShowMoreBtn, setCatalogueShowMoreBtn]=useState(false);
  const [getCataloguePage, setCataloguePage]=useState(1);
  const [getupdateModal, setupdateModal]=useState(false);
  const [iframeSrc, setiframeSrc]=useState('');
  const iframeRef=useRef();


  const [show, setShow]=useState(false);
  const handleClose=() => {
    setAuthorDetail({});
    setShow(false)
  };


  useEffect(() => {
    getCatalogueApi();
    setLang(i18n.language);
  }, []);

  const openModal=() => {
    setupdateModal(true);
  };

  const closeModal=() => {
    setupdateModal(false);
  };

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');
    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  function handleShow(x, id) {
    x.preventDefault();

    setShow(true);
  }


  const search=() => {
    setCatalogue([]);
    getCatalogueApi();
  }

  function limitWord(text, limit=7) {
    const words=text.split(' ');
    if (words.length>limit) {
      return words.slice(0, limit).join(' ')+'...';
    } else {
      return text;
    }
  }

  const getCatalogueApi=() => {
    var formdata=new FormData();
    let nameVal=(nameRef.current.value).trim();
    let catVal=(categoryRef.current.value).trim();
    var formdata=new FormData();
    formdata.append('keyword', nameVal);
    formdata.append('category', catVal);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getcatalogue?page=${getCataloguePage}`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          if ((result.data.data).length===0) {
            setCatalogueShowMoreBtn(false);
          } else {
            setCatalogueShowMoreBtn(true);
          }
          const newExploreProductData=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;
            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;
            final.author=data.author;
            final.ebook=data.ebook;
            return final;
          });
          setCatalogue(prevExploreProduct => [...prevExploreProduct, ...newExploreProductData]);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  function readBtn(x, ebook) {
    x.preventDefault();
    let framesrc=`http://flowpaper.com/flipbook/${config.ebookUrl}/${ebook}`;
    setiframeSrc(framesrc)
    openModal(true);
  }

  function prevent(x) {
    x.preventDefault();
  }


  return (
    <>
      <div className="main-container">
        {/* <h2 className="journals-title lead text-center" style={{ padding: '6px', margin: '0 0 1em 0', letterSpacing: '1px', textTransform: 'uppercase' }}>
                    {t('authors')} </h2> */}
        <h1 class="title text-center my-4 mb-5">{t('catalouge')}</h1>


        <div className="container-fluid mb-4">
          <div className="author-filter">
            <div className="row">
              <div className="col-md-5">
                <form className="singin-form">
                  <div className="form-group mb-0">
                    <label>{t('Filter By Name')}</label>
                    <input ref={nameRef} type="text" className="form-control" name="name" />
                  </div>
                </form>
              </div>
              <div className="col-md-5 mt-md-0 mt-5">
                <form className="singin-form" onSubmit={(x) => prevent(x)}>
                  <div className="form-group mb-0">
                    <label>{t('Filter By Category')}</label>
                    <select ref={categoryRef} name="category" id="category" className="select2">
                      <option value="">{t('Select Category')}</option>
                      {
                        getCategory?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>{(getLang=='en-US'||getLang=='en')? item.en:item.ar}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </form>
              </div>
              <div className="col-md-2 mt-md-0 mt-4">
                <button style={{ width: 'auto', padding: '10px 38px' }} onClick={search} className="axil-btn btn-bg-primary btn-sm">Search</button>
              </div>
            </div>

          </div>
        </div>
        <div className="journals container-fluid">
          <div className="row media-list gx-1">

            {
              getCatalogue?.map((item, index) => {

                return (
                  <div className="media col-md-4" key={index}>
                    <div className="border-box h-100">
                      <div className="media-left">
                        <a href="javascript:void(0);">
                          <img className="media-object" src={`${config.baseImg}/${item.thumb_img}`} />
                        </a>
                      </div>
                      <div className="media-body">
                        <h3 className="media-heading">
                          <a href="javascript:void(0);" rel="bookmark">
                            {(getLang=='en-US'||getLang=='en')? limitWord(item.name_en):limitWord(item.name_ar)}
                          </a>
                          <ul>
                            <li>{t('Author')}: {(getLang=='en-US'||getLang=='en')? (item.author.name):(item.author.name_ar)}</li>
                            <li>{t('ISBN')}: {item.sku}</li>
                          </ul>
                        </h3>
                        <div className="description">
                          {/* <p className="para">{(getLang=='en-US'||getLang=='en')? limitWord(item.description):limitWord(item.description_ar)}</p> */}
                        </div>
                        <ul className="nav nav-pills">
                          <li className="view" style={{ border: 'none' }}>
                            <button type="button" onClick={(x) => readBtn(x, item.ebook)} style={{ width: 'auto', padding: '5px 20px' }} className="axil-btn btn-bg-primary btn-sm">{t('Read')}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>



      <Modal show={getupdateModal} centered size="lg" fullscreen>

        <Modal.Body className="update-modal-body p-0">
          <iframe src={iframeSrc} width="100%" height="800" style={{ border: "none" }} allowFullScreen>
          </iframe>
        </Modal.Body>
        <Modal.Footer className="update-modal-footer">
          <Button style={{ width: 'auto' }} variant="secondary" onClick={closeModal}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>




    </>

  )
}