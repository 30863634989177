import React from 'react'
import "./Book.css"
import HTMLFlipBook from "react-pageflip";
import page0 from "./../../Assets/images/book/back.jpeg"
import page1 from "./../../Assets/images/book/one_page-0001.jpg"
import page2 from "./../../Assets/images/book/one_page-0002.jpg"
import page3 from "./../../Assets/images/book/one_page-0003.jpg"
import page4 from "./../../Assets/images/book/one_page-0004.jpg"
import page5 from "./../../Assets/images/book/one_page-0005.jpg"
import page6 from "./../../Assets/images/book/one_page-0006.jpg"
import page7 from "./../../Assets/images/book/one_page-0007.jpg"
import page8 from "./../../Assets/images/book/one_page-0008.jpg"
import page9 from "./../../Assets/images/book/one_page-0009.jpg"
import page10 from "./../../Assets/images/book/one_page-0042.jpg"
import secureLocalStorage from "react-secure-storage";

export default function Book() {

    return (
        <div className='book'>
            <HTMLFlipBook width={300} height={500}>
                <div className="demoPage">

                    {/* <img className="book-img back" src={page0}></img> */}

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page1}></img>

                </div>
                <div className="demoPage">
                    <img className="book-img" src={page2}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page3}></img>

                </div>
                <div className="demoPage">
                    <img className="book-img" src={page4}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page5}></img>

                </div>
                <div className="demoPage">
                    <img className="book-img" src={page6}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page7}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page8}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page9}></img>

                </div>
                <div className="demoPage">

                    <img className="book-img" src={page10}></img>

                </div>
                <div className="demoPage">

                    {/* <img className="book-img back" src={page0}></img> */}

                </div>
            </HTMLFlipBook>
        </div>
    )

}
