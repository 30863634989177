import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import config, { checkStorage, getCurrency, getStorage } from "../Config";
import { getSecureStorage, getTokenValue, removeSecureStorage, setCustomerLogin } from "../checkSecureLogin";

export default function Cart() {
  const navigate=useNavigate();
  const emailref=useRef(null);
  const passwordref=useRef(null);
  const [getLang, setLang]=useState('en');
  const signup=() => {
    return navigate("/signup")
  }
  const forgotPassword=() => {
    return navigate("/forgotpassword")
  }
  const { t, i18n }=useTranslation();


  useEffect(() => {
    setLang(i18n.language);
  }, [])

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  function login(x) {
    x.preventDefault();
    let email=(emailref.current.value).trim();
    let password=(passwordref.current.value).trim();

    if (!isValidEmail(email)) {
      Toastify({
        text: t('Invalid Email'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    if (password=='') {
      Toastify({
        text: t('Enter Password'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    var myHeaders=new Headers();
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    const cartdata=getSecureStorage('cart');
    if (cartdata&&cartdata.length!=0) {
      formdata.append("cart", JSON.stringify(cartdata));
    }
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.message==='Login successful') {
          setCustomerLogin(result.user.id, result.token);
          //removeSecureStorage('cart');
          return navigate('/myaccount');
        } else {

          Toastify({
            text: result.message,
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();

        }
      }).catch(error => {
        Toastify({
          text: t('Invalid Login'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();

      }).finally(() => {

      });

  }

  function isValidEmail(email) {
    const emailPattern=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test method to check if the email matches the pattern
    return emailPattern.test(email);
  }

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }

  return (
    <div className="axil-signin-area">
      {/* Start Header */}
      <div className="signin-header">
        <div className="row align-items-center">
          <div className="col-sm-4">
            <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/')} className="site-logo"><img src="./assets/images/logo/logo.png" alt="logo" /></a>
          </div>
          <div className="col-sm-8">
            <div className="singin-header-btn">
              <p onClick={(x) => navigateTo(x, '/signup')}>{t('not_a_member?')}</p>
              <a href="javascript:void(0);" className="axil-btn btn-bg-secondary sign-up-btn" onClick={(x) => navigateTo(x, '/signup')}>{t('sign_up_now')}</a>
            </div>
          </div>
        </div>
      </div>
      {/* End Header */}
      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="axil-signin-banner bg_image bg_image--9">
            <h3 className="title">{t('login_now_and_mark_your_favorite_books!')}</h3>
          </div>
        </div>
        <div className="col-lg-6 offset-xl-2">
          <div className="axil-signin-form-wrap">
            <div className="axil-signin-form">
              <h3 className="title">{t('sign_in_to_qatar_university')}</h3>
              <p className="b2 mb--55">{t('enter_your_details_below')}</p>
              <form className="singin-form">
                <div className="form-group">
                  <label>{t('email')}</label>
                  <input type="email" id="email" ref={emailref} className="form-control" name="email" placeholder={t('email')} />
                </div>
                <div className="form-group">
                  <label>{t('password')}</label>
                  <input type="password" id="password" ref={passwordref} className="form-control" name="password" placeholder={t('password')} />
                </div>
                <div className="form-group d-flex align-items-center justify-content-between">
                  <button type="button" onClick={(x) => login(x)} className="axil-btn btn-bg-primary submit-btn">{t('sign_in')}</button>
                  <a href="javascript:void(0);" className="forgot-btn" onClick={forgotPassword}>{t('forget_password?')}</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
