import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

import { checkSecureLogin, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import swal from "sweetalert";

export default function Wishlist() {

  const { t, i18n }=useTranslation();
  const [getLang, setLang]=useState('en');
  const [getWishlistArr, setWishlistArr]=useState([]);
  const navigate=useNavigate();

  useEffect(() => {
    setLang(i18n.language);
    getWishlist();
  }, [])


  function deleteItem(x, id) {
    x.preventDefault();
    if (!checkSecureLogin()) {
      return false;
    }
    var btn_ele=x.currentTarget;
    (btn_ele).disabled=true;
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("wishlist_id", id);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/remove/wishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {          
          getWishlist();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {

        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        (btn_ele).disabled=false;
      });
  }

  const getWishlist=() => {
    if (!checkSecureLogin()) {
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/get/wishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          var finalcart=[];
          finalcart=(result.data).map((item, index) => {
            let name=[];
            let product_id=item.product_id;
            let wishlist_id=item.id;
            let image='';
            let price='';
            if (item.product) {
              for (let key in (item.product)) {
                name=JSON.parse((item.product)['name']);
                image=(item.product)['thumb_img'];
                price=(item.product)['new_price'];
              }
            }
            return {
              product_id: product_id,
              image: image,
              name: name,
              price: price,
              wishlist_id: wishlist_id
            }
          })

          let total=0;

          finalcart.map((item, index) => {
            total+=item.price
          })
          console.log(finalcart);
          setWishlistArr(finalcart);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }
  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }
  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    var style_link=document.getElementById('style-type');

    if (currentLanguage=='ar') {
      style_link.href='../assets/css/style-rtl.min.css';
    } else {
      style_link.href='../assets/css/style.min.css';
    }
  };


  function addTocart(x, id) {
    x.preventDefault();

    if (!checkSecureLogin()) {
      Toastify({
        text: t('Please Login'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());

    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/add/cart`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {

      });
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Wishlist Area  */}
        <div className="axil-wishlist-area axil-section-gap" style={{ marginTop: '60px' }}>
          <div className="container">
            <div className="product-table-heading">
              <h4 className="title">{t('my_wish_list_on_qatar_university')}</h4>
            </div>
            <div className="table-responsive">
              <table className="table axil-product-table axil-wishlist-table">
                <thead>
                  <tr>
                    <th scope="col" className="product-remove" />
                    <th scope="col" className="product-thumbnail">{t('product')}</th>
                    <th scope="col" className="product-title" />
                    <th scope="col" className="product-price">{t('unit_price')}</th>
                    <th scope="col" className="product-stock-status">{t('stock_status')}</th>
                    <th scope="col" className="product-add-cart" />
                  </tr>
                </thead>
                <tbody>
                  {
                    getWishlistArr?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="product-remove"><button type="button" onClick={(x) => deleteItem(x, item.wishlist_id)} className="remove-wishlist"><i className="fal fa-times" /></button></td>
                          <td className="product-thumbnail"><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/'+item.product_id)}><img src={`${config.baseImg}/${item.image}`} alt="" /></a></td>
                          <td className="product-title"><a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/product/'+item.product_id)}>{(getLang=='en-US' || getLang=='en')? item.name.en:item.name.ar}</a></td>
                          <td className="product-price" data-title="Price"><span className="currency-symbol">{getCurrency()} </span>{toDecimal(item.price)}</td>
                          <td className="product-stock-status" data-title="Status">{t('In Stock')}</td>
                          <td className="product-add-cart"><a href="javascript:void(0);" onClick={(x) => addTocart(x, item.product_id)} className="axil-btn btn-outline">{t('Add to Cart')}</a></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* End Wishlist Area  */}
      </main>
      <div className="service-area" style={{ marginTop: "60px" }}>
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

