import React from "react";
import { useTranslation } from 'react-i18next';
import { useEffect,useState,useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export default function PublishWithUs() {
  const { t, i18n } = useTranslation();

  // Change the language when a button is clicked
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage = i18n.language;
    let currDirEle = document.getElementById('head-lang');
    let currDir = currDirEle.getAttribute('dir');

    if (currentLanguage == 'ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };
    return (
        <div>
        <main className="main-wrapper">
          {/* Start Breadcrumb Area  */}
          <div className="axil-breadcrumb-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                  <div className="inner">
                    <ul className="axil-breadcrumb">
                      <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                      <li className="separator" />
                      <li className="axil-breadcrumb-item active" aria-current="page">{t('publish_with_us')}</li>
                    </ul>
                    <h1 className="title">{t('publish_with_us')}</h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4">
                  <div className="inner">
                    <div className="bradcrumb-thumb">
                      <img src="assets/images/product/product-45.png" alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb Area  */}
          {/* Start About Area  */}
          <div className="axil-about-area about-style-1 axil-section-gap ">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-6">
                  <div className="about-thumbnail">
                    <div className="thumbnail">
                      <img src="./assets/images/about/about-01.png" alt="About Us" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-6">
                  <div className="about-content content-right">
                    <span className="title-highlighter highlighter-primary2"> <i className="far fa-shopping-basket" />About Store</span>
                    <h3 className="title">Why publish with us ?</h3>
                    <span className="text-heading">Salesforce B2C Commerce can help you create unified, intelligent digital commerce
                      experiences — both online and in the store.</span>
                    <div className="row">
                      <div className="col-xl-6">
                        <p>Empower your sales teams with industry tailored
                          solutions that support manufacturers as they go
                          digital, and adapt to changing markets &amp; customers
                          faster by creating new business.</p>
                      </div>
                      <div className="col-xl-6">
                        <p className="mb--0">Salesforce B2B Commerce offers buyers the
                          seamless, self-service experience of online
                          shopping with all the B2B</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}
          {/* Start About Area  */}
          <div className="about-info-area">
            <div className="container">
              <div className="row row--20">
                <div className="col-lg-4">
                  <div className="about-info-box">
                    <div className="thumb">
                      <img src="assets/images/about/shape-01.png" alt="Shape" />
                    </div>
                    <div className="content">
                      <h6 className="title">40,000+ Happy Customer</h6>
                      <p>Empower your sales teams with industry
                        tailored solutions that support.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="about-info-box">
                    <div className="thumb">
                      <img src="assets/images/about/shape-02.png" alt="Shape" />
                    </div>
                    <div className="content">
                      <h6 className="title">16 Years of Experiences</h6>
                      <p>Empower your sales teams with industry
                        tailored solutions that support.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="about-info-box">
                    <div className="thumb">
                      <img src="assets/images/about/shape-03.png" alt="Shape" />
                    </div>
                    <div className="content">
                      <h6 className="title">12 Awards Won</h6>
                      <p>Empower your sales teams with industry
                        tailored solutions that support.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}
          {/* Start About Area  */}
          <div className="axil-about-area about-style-2">
            <div className="container">
              <div className="row align-items-center mb--80 mb_sm--60">
                <div className="col-lg-5">
                  <div className="about-thumbnail">
                    <img src="assets/images/about/about-02.png" alt="about" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-content content-right">
                    <span className="subtitle">Features #01</span>
                    <h4 className="title">Solutions that work together</h4>
                    <p>Publish your eCommerce site quickly with our easy-to-use store builder— no
                      coding required. Migrate your items from your point of sale system or turn your
                      Instagram feed into a shopping site and start selling fast. Square Online works
                      for all kinds of businesses—retail, restaurants, services.</p>
                    <a href="contact.html" className="axil-btn btn-outline">Get In Touch</a>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-2">
                  <div className="about-thumbnail">
                    <img src="assets/images/about/about-03.png" alt="about" />
                  </div>
                </div>
                <div className="col-lg-7 order-lg-1">
                  <div className="about-content content-left">
                    <span className="subtitle">Features #01</span>
                    <h4 className="title">Solutions that work together</h4>
                    <p>Publish your eCommerce site quickly with our easy-to-use store builder— no
                      coding required. Migrate your items from your point of sale system or turn your
                      Instagram feed into a shopping site and start selling fast. Square Online works
                      for all kinds of businesses—retail, restaurants, services.</p>
                    <a href="contact.html" className="axil-btn btn-outline">Get In Touch</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}1
        </main>
        <div className="service-area">
          <div className="container">
            <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
              <div className="col">
                <div className="service-box service-style-2">
                  <div className="icon">
                    <img src="./assets/images/icons/service1.png" alt="Service" />
                  </div>
                  <div className="content">
                    <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                    <p>{t('Tell about your service.')}</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="service-box service-style-2">
                  <div className="icon">
                    <img src="./assets/images/icons/service2.png" alt="Service" />
                  </div>
                  <div className="content">
                    <h6 className="title">{t('Money Back Guarantee')}</h6>
                    <p>{t('Within 10 days.')}</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="service-box service-style-2">
                  <div className="icon">
                    <img src="./assets/images/icons/service3.png" alt="Service" />
                  </div>
                  <div className="content">
                    <h6 className="title">{t('24 Hour Return Policy')}</h6>
                    <p>{t('No question ask.')}</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="service-box service-style-2">
                  <div className="icon">
                    <img src="./assets/images/icons/service4.png" alt="Service" />
                  </div>
                  <div className="content">
                    <h6 className="title">{t('Pro Quality Support')}</h6>
                    <p>{t('24/7 Live support.')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}