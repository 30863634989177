import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";

import Slider from 'react-slick';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";


export default function Calendar() {
  const { t, i18n }=useTranslation();
  const events=[
    { title: 'Meeting', start: new Date() }
  ]
  const navigate=useNavigate();
  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }
  return (
    <main className="main-wrapper">
      <div className="axil-breadcrumb-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="inner">
                <ul className="axil-breadcrumb">
                  <li className="axil-breadcrumb-item"><a href="" onClick={(x) => navigateTo(x, '/')}>{t('home')}</a></li>
                  <li className="separator" />
                  <li className="axil-breadcrumb-item active" aria-current="page">{t('Event Calendar')}</li>
                </ul>
                <h1 className="title">{t('Event Calendar')}</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="inner">
                <div className="bradcrumb-thumb">
                  <img src="assets/images/product/product-45.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">

        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          events={events}
          eventContent={renderEventContent}
        />
      </div>
    </main>
  )
}
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}