import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
import config, { getCurrency } from "../Config";
import { checkSecureLogin, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function Footer() {
  const { t, i18n }=useTranslation();
  const [getContact, setContact]=useState({});
  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };


  const navigate=useNavigate();

  useEffect(() => {
    getContactApi();
  }, [])

  const getContactApi=() => {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/common/getcontact`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          console.log(result.status);
          setContact(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  const myaccount=() => {
    return navigate("/myaccount")
  }
  const signup=() => {
    return navigate("/signup")
  }
  const cart=() => {
    return navigate("/cart")
  }
  const wishlist=() => {
    return navigate("/wishlist")
  }
  const shop=() => {
    return navigate("/shop")
  }
  const privacypolicy=() => {
    return navigate("/privacypolicy")
  }
  const termsofuse=() => {
    return navigate("/termsofuse")
  }
  const about=() => {
    return navigate("/about")
  }
  const contact=() => {
    return navigate("/contact")
  }
  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }
  return (
    <footer className="axil-footer-area footer-style-2">
      {/* Start Footer Top Area  */}
      <div className="footer-top separator-top">
        <div className="container">
          <div className="row">
            {/* Start Single Widget  */}
            <div className="col-lg-3 col-sm-6">
              <div className="axil-footer-widget">
                <h5 className="widget-title">{t('support')}</h5>
                {/* <div class="logo mb--30">
                            <a href="/">
                                <img class="light-logo" src="assets/images/logo/logo.png" alt="Logo Images">
                            </a>
                        </div> */}
                <div className="inner">
                  <p> <div dangerouslySetInnerHTML={{ __html: getContact.address }} /></p>
                  <ul className="support-list-item">
                    <li><a href={`mailto:${getContact.email}`}><i className="fal fa-envelope-open" /> {getContact.email}</a></li>
                    <li><a href={`tel:${getContact.mobile}`}><i className="fal fa-mobile-alt" /> {getContact.mobile}</a></li>
                    <li><a href={`tel:${getContact.phone}`}><i className="fal fa-phone-alt" /> {getContact.phone}</a></li>
                    {/* <li><i class="fal fa-map-marker-alt"></i> 685 Market Street,  <br> Las Vegas, LA 95820, <br> United States.</li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* End Single Widget  */}
            {/* Start Single Widget  */}
            <div className="col-lg-4 col-sm-6">
              <div className="axil-footer-widget">
                <h5 className="widget-title">{t('account')}</h5>
                <div className="inner">
                  <ul>
                    <li><a href=" javascript:void(0);" onClick={(x) => navigateTo(x, 'myaccount')}>{t('my_account')}</a></li>
                    <li><a href=" javascript:void(0);" onClick={signup}>{t('login_register')}</a></li>
                    <li><a href=" javascript:void(0);" onClick={cart}>{t('cart')}</a></li>
                    <li><a href=" javascript:void(0);" onClick={wishlist}>{t('wishlist')}</a></li>
                    <li><a href=" javascript:void(0);" onClick={shop}>{t('shop')}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Single Widget  */}
            {/* Start Single Widget  */}
            <div className="col-lg-4 col-sm-6">
              <div className="axil-footer-widget">
                <h5 className="widget-title">{t('quick_link')}</h5>
                <div className="inner">
                  <ul>
                    <li><a href="javascript:void(0);" onClick={privacypolicy}>{t('privacy_policy')}</a></li>
                    <li><a href="javascript:void(0);" onClick={termsofuse}>{t('terms_of_use')}</a></li>
                    <li><a href="javascript:void(0);">{t('faq')}</a></li>
                    <li><a href="javascript:void(0);" onClick={about}>{t('about')}</a></li>
                    <li><a href="javascript:void(0);" onClick={contact}>{t('contact')}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            {/* End Single Widget  */}
            {/* Start Single Widget  */}
            <div className="col-lg-3 col-sm-6 d-none">
              <div className="axil-footer-widget">
                <h5 className="widget-title">{t('Comming Soon')}</h5>
                <div className="inner">
                  <div className="download-btn-group">
                    <div className="qr-code">
                      <img src="../assets/images/others/qr.png" style={{ height: '98px', width: '98px' }} alt="Axilthemes" />
                    </div>
                    <div className="app-link">
                      <a href="javascript:void(0);">
                        <img src="../assets/images/others/app-store.png" alt="App Store" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="../assets/images/others/play-store.png" alt="Play Store" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Widget  */}
          </div>
        </div>
      </div>
      {/* End Footer Top Area  */}
      {/* Start Copyright Area  */}
      <div className="copyright-area copyright-default separator-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4">
              <div className="social-share">
                <a target="_blank" href={getContact.facebook}><i className="fab fa-facebook-f" /></a>
                <a target="_blank" href={getContact.instagram}><i className="fab fa-instagram" /></a>
                <a target="_blank" href={getContact.twitter}><i className="fab fa-twitter" /></a>
                <a target="_blank" href={getContact.linkedin}><i className="fab fa-linkedin-in" /></a>
                <a target="_blank" href={getContact.snapchat}><i className="fab fa-snapchat-square" /></a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12">
              <div className="copyright-left d-flex flex-wrap justify-content-center">
                <ul className="quick-link">
                  <li className="text-center mb-0">
                    <div>© 2023. {t('All rights reserved by')} <a target="_blank" href="javascript:void(0);" onClick={(x) => navigateTo(x, '/')}>Qatar University</a>.</div>
                    <small>{t('Developed By')}: <a target="_blank" href="https://eitmadtech.com/">Eitmadtech</a></small>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12">
              <div className="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center">
                <span className="card-text">Accept For</span>
                <ul className="payment-icons-bottom quick-link">
                  <li><img src="assets/images/icons/cart/cart-1.png" alt="paypal cart" /></li>
                  <li><img src="assets/images/icons/cart/cart-2.png" alt="paypal cart" /></li>
                  <li><img src="assets/images/icons/cart/cart-5.png" alt="paypal cart" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Copyright Area  */}
    </footer>
  );
}


