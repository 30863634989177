import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from 'react-i18next';
import config from "../Config";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import swal from "sweetalert";

export default function ProjectsAndProgram() {
  const { t, i18n }=useTranslation();

  const [getLang, setLang]=useState('en');
  const [getPost, setPost]=useState([]);

  const searchRef=useRef();
  const searchBtnRef=useRef();

  const [show, setShow]=useState(false);
  const [getPostDetail, setPostDetail]=useState({});
  const [currentPage, setCurrentPage]=useState(1);
  const [getShowMoreBtn, setShowMoreBtn]=useState(false);


  useEffect(() => {
    getPostApi();
  }, [currentPage])

  const handleClose=() => {
    setPostDetail({});
    setShow(false)
  };

  function handleShow(x, id) {
    x.preventDefault();
    var formdata=new FormData();
    formdata.append('id', id);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/getprojectprogram/detail`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setPostDetail(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {
      });

    setShow(true);
  }


  function getPostApi() {
    let searchVal=(searchRef.current.value).trim();

    var formdata=new FormData();
    formdata.append('search', searchVal);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    var postData=[];
    var final={};
    fetch(`${config.baseUrl}/getprojectprogram?page=${currentPage}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {

          if ((result.data.data).length===0) {
            setShowMoreBtn(false);
          } else {
            setShowMoreBtn(true);
          }

          (result.data.data).map((data, index) => {
            final={};
            final.id=data.id;
            final.title=data.title;
            final.ar_title=data.ar_title;
            final.description=data.description;
            final.ar_description=data.ar_description;
            final.thumb_img=data.thumb_img;
            final.banner_img=data.banner_img;
            final.keywords=data.keywords;
            final.news_date=data.news_date;
            postData.push(final);
          })
          setPost(prevExploreProduct => [...prevExploreProduct, ...postData]);

        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }

  function limitWord(htmlText) {
    let wordLimit=50;
    let textWithoutTags=htmlText.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words=textWithoutTags.split(/\s+/); // Split text into words

    if (words.length>wordLimit) {
      const limitedWords=words.slice(0, wordLimit);
      const limitedText=limitedWords.join(' ')+(words.length>wordLimit? '...':'');
      const limitedHTML=htmlText.replace(textWithoutTags, limitedText);
      return limitedHTML;
    } else {
      return htmlText;
    }
  }

  const loadMore=() => {
    setCurrentPage(currentPage+1);
  }

  function searchBtn(x) {
    x.preventDefault();
    setPost([]);
    if (currentPage===1) {
      getPostApi();
    }
    setCurrentPage(1);
  }


  return (
    <div>
      <br />
      <main className="main-wrapper">

        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('projects_and_programs')}</li>
                  </ul>
                  <h1 className="title">{t('projects_and_programs')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="assets/images/product/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Blog Area  */}
        <div className="axil-blog-area axil-section-gap">


          <div className="container" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div className="axil-single-widget mt--40 widget_search">
              <div className="row">
                <div className="col-md-8">
                  <form class="singin-form"><div class="form-group mb-0">
                    <label>{t('Search')}</label>
                    <input type="text" ref={searchRef} class="form-control" name="name" />
                  </div>
                  </form>
                </div>
                <div className="col-md-4">
                  <button onClick={(x) => searchBtn(x)} ref={searchBtnRef} style={{ width: "auto", padding: "10px 38px" }} class="axil-btn btn-bg-primary btn-sm">Search</button>
                </div>
              </div>

            </div>
          </div>

          {
            getPost?.map((item, index) => {
              return (
                <div className="post-single-wrapper position-relative pb-5" key={index}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <img src="assets/images/blog/blog-single.jpg" style={{ borderRadius: '8px' }} alt="blog Images" />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-1">
                        <div className="d-flex flex-wrap align-content-start h-100">
                          <div className="position-sticky sticky-top">
                            <div className="post-details__social-share">
                              <span className="share-on-text">Share on:</span>
                              <div className="social-share">
                                <a href="#"><i className="fab fa-facebook-f" /></a>
                                <a href="#"><i className="fab fa-instagram" /></a>
                                <a href="#"><i className="fab fa-twitter" /></a>
                                <a href="#"><i className="fab fa-linkedin-in" /></a>
                                <a href="#"><i className="fab fa-discord" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-11 axil-post-wrapper">
                        <div className="post-heading pb-1 mb-5">
                          <h2 className="title" onClick={(x) => handleShow(x, item.id)}>{(getLang=='en-US'||getLang=='en')? item.title:item.ar_title}</h2>

                        </div>
                        <p>

                          <div
                            dangerouslySetInnerHTML={{ __html: limitWord(((getLang=='en-US'||getLang=='en')? item.description:item.ar_description)) }}
                          />
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>


        {
          (getShowMoreBtn)?
            <div class="row">
              <div class="col-lg-12 text-center mt--20 mt_sm--0">
                <a href="javascript:void(0);" class="axil-btn btn-bg-lighter btn-load-more" onClick={loadMore}>{t('Show More')}</a>
              </div>
            </div>:<></>
        }

      </main>



      <Modal className="author-modal" show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">{(getLang=='en-US'||getLang=='en')? (getPostDetail.title):(getPostDetail.ar_title)}</Modal.Title>
          <button onClick={handleClose} className="modal-custom-close"><i className="fas fa-times"></i></button>
        </Modal.Header>
        <Modal.Body>

          <div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <img className="modal-img" src={`${config.baseImg}/${getPostDetail.thumb_img}`} />
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: (getLang=='en-US'||getLang=='en')? (getPostDetail.description):(getPostDetail.ar_description) }} />
                  </p>

                </div>

              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>

      <br />
      <br />
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20 seperator-top">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
