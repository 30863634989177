import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import config from "../Config";
import i18n from 'i18next';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

export default function About() {
  const { t, i18n } = useTranslation();
  const [getLang, setLang] = useState('en');
  const [getGallery, setGallery] = useState([]);
  const [getCurrentPage, setCurrentPage] = useState(null);
  const [getFirstPageUrl, setFirstPageUrl] = useState("");
  const [getFrom, setFrom] = useState(null);
  const [getLastPage, setLastPage] = useState(null);
  const [getLastPageUrl, setLastPageUrl] = useState("");
  const [getLinks, setLinks] = useState([]);
  const [getNextPageUrl, setNextPageUrl] = useState("");
  const [getPath, setPath] = useState("");
  const [getPerPage, setPerPage] = useState();
  const [getPrevPageUrl, setPrePageUrl] = useState("");
  const [getTo, setTo] = useState(null);
  const [getTotal, setTotal] = useState(null);
  useEffect(() => {
    getGalleryApi();
    setLang(i18n.language);
  }, []);
  // Change the language when a button is clicked
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage = i18n.language;
    let currDirEle = document.getElementById('head-lang');
    let currDir = currDirEle.getAttribute('dir');

    if (currentLanguage == 'ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };
  function getGalleryApi() {
    var formdata = new FormData();
    var requestOptions = {
      method: 'POST',
      body: formdata,
    };
    var newsGallery = [];
    var final = {};
    fetch(`${config.baseUrl}/getgallery`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === 'success') {
          //(result.data).map((data, index) => {
          setGallery(result.data.data);
          setCurrentPage(result.data.current_page);
          setFirstPageUrl(result.data.first_page_url);
          setFrom(result.data.from);
          setLastPage(result.data.last_page);
          setLastPageUrl(result.data.last_page_url);
          setLinks(result.data.links);
          setNextPageUrl(result.data.next_page_url);
          setPath(result.data.path);
          setPerPage(result.data.per_page);
          setPrePageUrl(result.data.prev_page_url);
          setTo(result.data.to);
          setTotal(result.data.total);
          //})

        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }
  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('gallery')}</li>
                  </ul>
                  <h1 className="title">{t('gallery')}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="journal-page journals">
        <ul className="row media-list">
          {
            getGallery && getGallery.map && getGallery.map((item, index) => {

              return (
                <div className="author-col col-md-3" key={index}>
                  <div className="card" style={{ width: '25rem' }}>
                    <img src={`${config.baseImg}/${item.image}`} />
                    <div className="card-body">
                      <p className="card-text">{item.description}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </ul>
      </div>
      <div className="row">
        <div className="col-lg-12 text-center mt--20 mt_sm--0">
          <a
            href="shop.html"
            className="axil-btn btn-bg-lighter btn-load-more"
          >
            Show More
          </a>
        </div>
      </div>
    </div>


  )
}

