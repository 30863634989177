import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import swal from "sweetalert";
import config, { getCurrency } from "../Config";
import "./shop.css";
import secureLocalStorage from "react-secure-storage";

import { checkSecureLogin, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import MultiRangeSlider from "./MultiRangeSlider";


export default function Shop() {
  const navigate=useNavigate();
  const [getLang, setLang]=useState('en');
  const [getProduct, setProduct]=useState([]);
  const [getProductPage, setProductPage]=useState(1);
  const [getProductShowMoreBtn, setProductShowMoreBtn]=useState(false);
  const { t, i18n }=useTranslation();
  const [getSearch, setSearch]=useState([]);
  const searchBox=useRef();
  const [getCategory, setCategory]=useState([]);
  const [selectedCategories, setSelectedCategories]=useState([]);
  const [isFilter, setIsFilter]=useState('');

  const [minPrice, setMinPrice]=useState(0);
  const [maxPrice, setMaxPrice]=useState(100);
  const sortRef=useRef();
  const { cat_id }=useParams();

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  useEffect(() => {

    setLang(i18n.language);

    getCategoryApi();
  }, [])

  useEffect(() => {
    getProductApi();
  }, [getProductPage])

  useEffect(() => {

    if (cat_id) {
      let ele=document.getElementById(`cat_check-${cat_id}`);
      if (ele) {
        ele.checked=true;
      }
    }

  }, [getCategory])


  const getProductApi=() => {

    var btn_ele=(document.getElementById('filter-btn'));
    var btn_html=btn_ele.innerHTML;

    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';

    var formdata=new FormData();
    let searchValue=(searchBox.current.value).trim();
    let sort=(sortRef.current.value).trim();

    formdata.append('keyword', searchValue);
    if (cat_id&&selectedCategories.length===0) {
      setProduct([]);
      formdata.append('category', JSON.stringify([cat_id]));
    } else {
      formdata.append('category', JSON.stringify(selectedCategories));
    }

    formdata.append('sort', sort);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/books/getpublishedbook?page=${getProductPage}`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status==='success') {
          if ((result.data.data).length===0) {
            setProductShowMoreBtn(false);
          } else {
            setProductShowMoreBtn(true);
          }
          const newProductData=(result.data.data).map(data => {
            let final={};
            let name=JSON.parse(data.name);
            let publisher=JSON.parse(data.publisher);
            let description=JSON.parse(data.description);

            let images=JSON.parse(data.images);
            let tags=JSON.parse(data.tags);
            let return_policy=JSON.parse(data.return_policy);

            final.id=data.id;
            final.sku=data.sku;
            final.publishing_year=data.publishing_year;
            final.qty=data.qty;
            final.old_price=data.old_price;
            final.new_price=data.new_price;
            final.discount=data.discount;
            final.status=data.status;
            final.is_published=data.is_published;
            final.category_id=data.category_id;
            final.thumb_img=data.thumb_img;
            final.images=images;
            final.name_en=name.en;
            final.name_ar=name.ar;
            final.publisher_en=publisher.en;
            final.publisher_ar=publisher.ar;
            final.description_en=description.en;
            final.description_ar=description.ar;


            final.tags_en=tags.en;
            final.tags_ar=tags.ar;
            final.return_policy_en=return_policy.en;
            final.return_policy_ar=return_policy.ar;

            return final;
          });
          setProduct(prevProduct => [...prevProduct, ...newProductData]);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {
        btn_ele.removeAttribute('disabled');
        btn_ele.innerHTML=btn_html;
      });
  }

  function gotoPage(x, path) {
    x.preventDefault();
    return navigate(path);
  }

  function producteLoadMore(x) {
    x.preventDefault();
    let final=getProductPage+1;
    setProductPage(final);
  }

  const handleCheckboxChange=(e, categoryId) => {
    const isChecked=e.target.checked;
    if (isChecked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(selectedCategories.filter(id => id!==categoryId));
    }

  };

  function getCategoryApi() {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    var catData=[];
    fetch(`${config.baseUrl}/common/getallbookcategory`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          (result.data).map((data, index) => {
            let name=JSON.parse(data.name);
            name.id=data.id;
            name.image=data.image;
            catData.push(name);
          })

          setCategory(catData);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }



  function addToWishlist(x, id) {
    x.preventDefault();

    var btn_ele=x.currentTarget;
    var btn_html=btn_ele.innerHTML;

    if (!checkSecureLogin()) {
      Toastify({
        text: t('Please Login'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    if (btn_ele.getAttribute('disabled')=='true') {
      return false;
    }

    btn_ele.setAttribute('disabled', true);
    btn_ele.innerHTML='<i class="fas fa-spinner fa-spin"></i>';

    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("product_id", id);
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/books/setwishlist`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        btn_ele.removeAttribute('disabled');
        btn_ele.innerHTML=btn_html;
      });
  }


  const handleMinPriceChange=(event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange=(event) => {
    setMaxPrice(event.target.value);
  };

  useEffect(() => {
    setProduct([]);
    getProductApi();
  }, [isFilter])

  function filterBtn(x) {
    x.preventDefault();
    setIsFilter(Math.floor(Math.random()*(100-2))+2);
  }

  const sortOnchange=() => {

    setProductPage(1);
  }

  const resetFilter=() => {
    searchBox.current.value='';
    setSelectedCategories([]);
    filterBtn();
  }

  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item">
                      <a href="/">{t('home')}</a>
                    </li>
                    <li className="separator" />
                    <li
                      className="axil-breadcrumb-item active"
                      aria-current="page"
                    >
                      {t('my_account')}
                    </li>
                  </ul>
                  <h1 className="title">{t('explore_all_books')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img
                      src="assets/images/product/product-45.png"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* End Breadcrumb Area  */}
        {/* Start Shop Area  */}
        <div className="axil-shop-area axil-section-gap bg-color-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="axil-shop-sidebar">
                  <div className="d-lg-none">
                    <button className="sidebar-close filter-close-btn">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                  <div className="toggle-list product-categories active">
                    <h6 className="title">{t('categories')}</h6>
                    <div className="shop-submenu">
                      <ul>
                        {
                          getCategory?.map((item, index) => {
                            return (
                              <li className="current-cat" key={index}>
                                <div className="form-check">
                                  <input onChange={(e) => handleCheckboxChange(e, item.id)} className="form-check-input" type="checkbox" name="category_checkbox" value={item.id} id={`cat_check-${item.id}`} />
                                  <label className="form-check-label" for={`cat_check-${item.id}`}>{(getLang=='en-US'||getLang=='en')? item.en:item.ar}</label>
                                </div>
                              </li>
                            )
                          })
                        }


                      </ul>
                    </div>
                  </div>

                  <div className="toggle-list product-price-range active d-none">
                    <h6 className="title">{t('price')}</h6>
                    <div className="shop-submenu">

                      <form action="#" className="mt--15">
                        <div id="slider-range" />
                        <div className="mt--10">

                          <MultiRangeSlider
                            min={50}
                            max={1000}
                            onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <button type="button" id="filter-btn" onClick={(x) => filterBtn(x)} className="axil-btn btn-bg-primary mb-3 mt-2">{t('Filter')}</button>
                  <button type="button" onClick={resetFilter} className="axil-btn btn-bg-primary">{t('all_reset')}</button>

                </div>
                {/* End .axil-shop-sidebar */}
              </div>
              <div className="col-lg-9">
                <div className="row">

                  <div className="col-lg-6">
                    <input type="text" id="email" class="form-control searchbox" ref={searchBox} name="search" placeholder={`${t('Search by Title or Authorname')}...`} />
                  </div>
                  <div className="col-lg-6">
                    <div className="axil-shop-top mb--40">

                      <div className="category-select align-items-center justify-content-lg-end justify-content-between">

                        <select ref={sortRef} onChange={sortOnchange} className="single-select">
                          <option value='latest'>{t('Sort by Latest')}</option>
                          <option value='oldest'>{t('Sort by Oldest')}</option>
                          <option value='name'>{t('Sort by Name')}</option>
                          <option value='price'>{t('Sort by Price')}</option>
                        </select>
                        {/* End Single Select  */}
                      </div>
                      <div className="d-lg-none">
                        <button className="product-filter-mobile filter-toggle">
                          <i className="fas fa-filter" /> FILTER
                        </button>
                      </div>
                    </div>
                  </div>



                </div>
                {/* End .row */}
                <div className="row row--15">

                  {
                    getProduct?.map((item, index) => {
                      return (
                        <div className="col-xl-4 col-sm-6" key={index}>
                          <div className="axil-product product-style-one mb--30">
                            <div className="thumbnail">
                              <a href={`product/${item.id}`} onClick={(x) => gotoPage(x, `/product/${item.id}`)} >
                                <img className="product-img" src={`${config.baseImg}/${item.thumb_img}`} alt="Product Images" />
                              </a>
                              <div className="label-block label-right">
                                <div className="product-badget">10% OFF</div>
                              </div>
                              <div className="product-hover-action">
                                <ul className="cart-action">
                                  <li className="wishlist">
                                    <a href="javascript:void(0);" onClick={(x) => addToWishlist(x, item.id)}>
                                      <i className="far fa-heart" />
                                    </a>
                                  </li>
                                  <li className="select-option">
                                    <a href="cart.html">Add to Cart</a>
                                  </li>
                                  <li className="quickview">
                                    <a
                                      href={`product/${item.id}`} onClick={(x) => gotoPage(x, `/product/${item.id}`)}

                                    >
                                      <i className="far fa-eye" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="product-content" style={{ marginTop: "12px" }}>
                              <div className="inner">
                                <h5 className="title">
                                  <a href="single-product.html">
                                    {(getLang=='ar')? item.name_ar:item.name_en}
                                  </a>
                                </h5>
                                <div className="product-price-variant">
                                  <span className="price current-price">{getCurrency()+' '+item.new_price}</span>
                                  {
                                    (item.new_price<item.old_price)?
                                      <span className="price old-price">{getCurrency()+' '+item.old_price}</span>
                                      :<></>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>

                {
                  (getProductShowMoreBtn)?
                    <div className="row">
                      <div className="col-lg-12 text-center mt--20 mt_sm--0">
                        <a href="javascript:void(0);" onClick={(x) => producteLoadMore(x)} className="axil-btn btn-bg-lighter btn-load-more">
                          {t('Show More')}
                        </a>
                      </div>
                    </div>:<></>
                }

              </div>
            </div>
          </div>
          {/* End .container */}
        </div>
        {/* End Shop Area  */}
      </main>
      <br />
      <br />
      <br />
      <div className="service-area">
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20 seperator-top">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
