import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, removeStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function Cart() {
  const { t, i18n }=useTranslation();
  const navigate=useNavigate();
  const [getLang, setLang]=useState('en');
  const [getData, setData]=useState([]);
  const [getCountry, setCountry]=useState([]);
  const [getOrder, setOrder]=useState([]);
  const name=useRef('');
  const country=useRef('');
  const new_pass=useRef('');
  const confirm_pass=useRef('');
  const submit_btn=useRef(null);
  useEffect(() => {
    setLang(i18n.language);
    getDetail();
    getCountryApi();
    getOrderApi();
  }, [])


  const getDetail=() => {
    if (!checkSecureLogin()) {
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/customer/details`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setData(result.data);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }


  const getOrderApi=() => {
    if (!checkSecureLogin()) {
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/customer/orders/get`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result.data.data);
          setOrder(result.data.data);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  function updateData(x) {
    x.preventDefault();
    let api_name=(name.current.value).trim();
    let api_country=(country.current.value).trim();
    let api_new_pass=(new_pass.current.value).trim();
    let api_confirm_pass=(confirm_pass.current.value).trim();

    if (api_name=='') {
      Toastify({
        text: t('Name Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (api_country=='') {
      Toastify({
        text: t('Country Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (api_new_pass=='') {
      Toastify({
        text: t('Password Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (api_confirm_pass=='') {
      Toastify({
        text: t('Confirm Password Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }
    (submit_btn.current).disabled=true;
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("name", api_name);
    formdata.append("country_id", api_country);
    formdata.append("new_password", api_new_pass);
    formdata.append("confirm_password", api_confirm_pass);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/customer/update/password`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          Toastify({
            text: result.data,
            duration: 3000,
            style: {
              background: config.toastSuccess,
            }
          }).showToast();

          new_pass.current.value='';
          confirm_pass.current.value='';
          return false;
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
        (submit_btn.current).disabled=false;
      });

  }

  function formatDateTime(date) {
    try {
      const parsedDate=new Date(date);
      const day=String(parsedDate.getDate()).padStart(2, '0'); // 'DD'
      const month=String(parsedDate.getMonth()+1).padStart(2, '0'); // 'MM'
      const year=parsedDate.getFullYear(); // 'YYYY'
      const hours=String(parsedDate.getHours()).padStart(2, '0'); // 'HH'
      const minutes=String(parsedDate.getMinutes()).padStart(2, '0'); // 'MM'

      return `${day}-${month}-${year} ${hours}:${minutes}`; // 'DD-MM-YYYY HH:MM'
    } catch {
      return date; // Return the original string if parsing fails
    }
  }

  const logout=() => {
    customerLogout();
    return navigate('/signin');
  }

  const getCountryApi=() => {
    if (!checkSecureLogin()) {
      return false;
    }
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/common/getcountry`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result);
          setCountry(result.data);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }


  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('my_account')}</li>
                  </ul>
                  <h1 className="title">{t('my_account')}</h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="inner">
                  <div className="bradcrumb-thumb">
                    <img src="assets/images/product/product-45.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrumb Area  */}
        {/* Start My Account Area  */}
        <div className="axil-dashboard-area axil-section-gap" style={{ marginTop: '50px' }}>
          <div className="container">
            <div className="axil-dashboard-warp">
              <div className="axil-dashboard-author">
                <div className="media">
                  <div className="thumbnail">
                    <img src="./assets/images/product/author1.png" alt="Hello Annie" />
                  </div>
                  <div className="media-body">
                    <h5 className="title mb-0">{t('Hello')}, {getData.name}</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-md-4">
                  <aside className="axil-dashboard-aside">
                    <nav className="axil-dashboard-nav">
                      <div className="nav nav-tabs" role="tablist">
                        <a className="nav-item nav-link active" data-bs-toggle="tab" href="#nav-dashboard" role="tab" aria-selected="true"><i className="fas fa-th-large" />{t('Dashboard')}</a>
                        <a className="nav-item nav-link" data-bs-toggle="tab" href="#nav-orders" role="tab" aria-selected="false"><i className="fas fa-shopping-basket" />{t('Orders')}</a>
                        {/* <a className="nav-item nav-link" data-bs-toggle="tab" href="#nav-downloads" role="tab" aria-selected="false"><i className="fas fa-file-download" />{t('Downloads')}</a> */}
                        {/* <a className="nav-item nav-link" data-bs-toggle="tab" href="#nav-address" role="tab" aria-selected="false"><i className="fas fa-home" />{t('Addresses')}</a> */}
                        <a className="nav-item nav-link" data-bs-toggle="tab" href="#nav-account" role="tab" aria-selected="false"><i className="fas fa-user" />{t('Account Details')}</a>
                        <a className="nav-item nav-link" href="javascript:void(0);" onClick={logout}><i className="fal fa-sign-out" />{t('Logout')}</a>
                      </div>
                    </nav>
                  </aside>
                </div>
                <div className="col-xl-9 col-md-8">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="nav-dashboard" role="tabpanel">
                      <div className="axil-dashboard-overview">
                        <div className="welcome-text">{t('Hello')}, {getData.name} (not <span>{getData.name}?</span> <a href="/signin">{t('Log Out')}</a>)</div>
                        <p>From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.</p>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-orders" role="tabpanel">
                      <div className="axil-dashboard-order">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">{t('Order')}</th>
                                <th scope="col">{t('Date')}</th>
                                <th scope="col">{t('Status')}</th>
                                <th scope="col">{t('Total')}</th>
                                <th scope="col">{t('Actions')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                getOrder?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">#{item.order_number}</th>
                                      <td>{formatDateTime(item.updated_at)}</td>
                                      <td>{t(item.status)}</td>
                                      <td>{getCurrency()} {toDecimal(item.pay_amount)}</td>
                                      <td><a href="#" className="axil-btn view-btn">View</a></td>
                                    </tr>
                                  )
                                })
                              }


                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-downloads" role="tabpanel">
                      <div className="axil-dashboard-order">
                        <p>You don't have any download</p>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-address" role="tabpanel">
                      <div className="axil-dashboard-address">
                        <p className="notice-text">The following addresses will be used on the checkout page by default.</p>
                        <div className="row row--30">
                          <div className="col-lg-6">
                            <div className="address-info mb--40">
                              <div className="addrss-header d-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">Shipping Address</h4>
                                <a href="#" className="address-edit"><i className="far fa-edit" /></a>
                              </div>
                              <ul className="address-details">
                                <li>Name: Annie Mario</li>
                                <li>Email: annie@example.com</li>
                                <li>Phone: 1234 567890</li>
                                <li className="mt--30">7398 Smoke Ranch Road <br />
                                  Las Vegas, Nevada 89128</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="address-info">
                              <div className="addrss-header d-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">Billing Address</h4>
                                <a href="#" className="address-edit"><i className="far fa-edit" /></a>
                              </div>
                              <ul className="address-details">
                                <li>Name: Annie Mario</li>
                                <li>Email: annie@example.com</li>
                                <li>Phone: 1234 567890</li>
                                <li className="mt--30">7398 Smoke Ranch Road <br />
                                  Las Vegas, Nevada 89128</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-account" role="tabpanel">
                      <div className="col-lg-9">
                        <div className="axil-dashboard-account">
                          <form className="account-details-form" method="post" onSubmit={(x) => updateData(x)}>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>{t('name')}</label>
                                  <input type="text" ref={name} className="form-control" value={getData.name} />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group mb--40">
                                  <label>Country/ Region</label>
                                  <select ref={country} className="select2" value={getData.country_id}>
                                    <option value="">Select Country</option>
                                    {
                                      getCountry?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>{(getLang=='en-US' || getLang=='en')? item.name:item.ar_name}</option>
                                        )

                                      })
                                    }

                                  </select>
                                  <p className="b3 mt--10">{t('This will be how your name will be displayed in the account section')}</p>
                                </div>
                              </div>
                              <div className="col-12">
                                <h5 className="title">{t('Password Change')}</h5>

                                <div className="form-group">
                                  <label>{t('New Password')}</label>
                                  <input type="password" ref={new_pass} name="new_password" className="form-control" />
                                </div>
                                <div className="form-group">
                                  <label>{t('Confirm New Password')}</label>
                                  <input type="password" ref={confirm_pass} name="confirm_password" className="form-control" />
                                </div>
                                <div className="form-group mb--0">
                                  <button type="submit" ref={submit_btn} className="axil-btn btn btn-primary" >{t('Save Changes')}</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End My Account Area  */}
      </main>
      <div className="service-area" style={{ marginTop: '40px' }}>
        <div className="container">
          <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service1.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Fast & Secure Delivery')}</h6>
                  <p>{t('Tell about your service.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service2.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Money Back Guarantee')}</h6>
                  <p>{t('Within 10 days.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service3.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('24 Hour Return Policy')}</h6>
                  <p>{t('No question ask.')}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="service-box service-style-2">
                <div className="icon">
                  <img src="./assets/images/icons/service4.png" alt="Service" />
                </div>
                <div className="content">
                  <h6 className="title">{t('Pro Quality Support')}</h6>
                  <p>{t('24/7 Live support.')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
