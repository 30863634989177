import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import HomeHeader from "./Components/Home/HomeHeader";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Wishlist from "./Components/Wishlist/Wishlist";
import Shop from "./Components/Shop/Shop";
import Header from "./Components/Header/Header";
import Cart from "./Components/Cart/Cart";
import Checkout from "./Components/Checkout/Checkout";
import MyAccount from "./Components/MyAccount/MyAccount";
import SignUp from "./Components/SignUp/SignUp";
import SignIn from "./Components/SignIn/SignIn";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Error from "./Components/Error/Error";
import Post from "./Components/Post/Post";
import Product from "./Components/Product/Product";
import NewsList from "./Components/NewsList/NewsList";
import NewsGrid from "./Components/NewsGrid/NewsGrid";
import TermsOfUse from "./Components/TermsOfUse/TermsOfUse";
import Journals from "./Components/Journals/Journals";
import OurTeam from "./Components/OurTeam/OurTeam";
import PublishWithUs from "./Components/PublishWithUs/PublishWithUs";
import Author from "./Components/Author/Author";
import PeerReview from "./Components/PeerReview/PeerReview";
import ResearchIntegrity from "./Components/ResearchIntegrity/ResearchIntegrity";
import PublishingForms from "./Components/PublishingForms/PublishingForms";
import ProjectsAndProgram from "./Components/ProjectsAndProgram/ProjectsAndProgram";
import Gallery from "./Components/Gallery/Gallery";
import DirectorsMessage from "./Components/DirectorsMessage/DirectorsMessage";
import Catalouge from "./Components/Catalouge/Catalouge";
import Calendar from "./Components/Calendar/Calendar";
import { useEffect } from "react";
import i18n from 'i18next';
//import './Assets/css/style-rtl.css';
import { useTranslation } from 'react-i18next';
import Book from "./Components/Book/Book";
import { ScrollToTop } from "./Components/ScrollToTop";
import { checkLogin } from "./Components/Config";
import { checkSecureLogin, redirectLogin } from "./Components/checkSecureLogin";
import Thankyou from "./Components/Thankyou/Thankyou";

function App() {
  const { t, i18n }=useTranslation();

  useEffect(() => {
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    var style_link=document.getElementById('style-type');
    //console.log(style_link);

    if (currentLanguage=='ar') {
      style_link.href='../assets/css/style-rtl.min.css';
    } else {
      style_link.href='../assets/css/style.min.css';
    }
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        {/* <Home_Header /> */}
        <ScrollToTop />
        <Routes>
          <Route element={<AuthLayout1 />}>
            <Route path="/" element={<Home />}></Route>
          </Route>

          <Route element={<AuthLayoutSecure />}>
            <Route path="/myaccount" element={<MyAccount />}></Route>

            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/wishlist" element={<Wishlist />}></Route>
            <Route path="/thankyou" element={<Thankyou />}></Route>
          </Route>

          <Route element={<AuthLayout />}>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/shop" element={<Shop />}></Route>
            <Route path="/shop/:cat_id" element={<Shop />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route path="/error" element={<Error />}></Route>
            <Route path="/post" element={<Post />}></Route>
            <Route path="/product/:id" element={<Product />}></Route>
            <Route path="/newslist" element={<NewsList />}></Route>
            <Route path="/newsgrid" element={<NewsGrid />}></Route>
            <Route path="/termsofuse" element={<TermsOfUse />}></Route>
            <Route path="/journals" element={<Journals />}></Route>
            <Route path="/ourteam" element={<OurTeam />}></Route>
            <Route path="/publishwithus" element={<PublishWithUs />}></Route>
            <Route path="/author" element={<Author />}></Route>
            <Route path="/peerreview" element={<PeerReview />}></Route>
            <Route path="/researchintegrity" element={<ResearchIntegrity />}></Route>
            <Route path="/publishingforms" element={<PublishingForms />}></Route>
            <Route path="/projectsandprogram" element={<ProjectsAndProgram />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/directorsmessage" element={<DirectorsMessage />}></Route>
            <Route path="/catalogue" element={<Catalouge />}></Route>
            <Route path="/calendar" element={<Calendar />}></Route>
          </Route>

          <Route element={<AuthLayout2 />}>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/signin" element={<SignIn />}></Route>
            <Route path="/login" element={<SignIn />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/resetpassword" element={<ResetPassword />}></Route>
            <Route path="/book" element={<Book />}></Route>
          </Route>

        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

const AuthLayout=() => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AuthLayoutSecure=() => {
  if (!checkSecureLogin()) {
    return redirectLogin();
  }
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AuthLayout1=() => {
  return (
    <>
      <HomeHeader />
      <Outlet />
      <Footer />
    </>
  );
};

const AuthLayout2=() => {

  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
