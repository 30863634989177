import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./Journals.css";
import { useTranslation } from 'react-i18next';
import config from "../Config";
import i18n from 'i18next';
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Journals() {
  const { t, i18n }=useTranslation();
  const [getLang, setLang]=useState('en');
  const [getJournal, setJournal]=useState([]);
  const [getJournalDetail, setJournalDetail]=useState({});
  const [show, setShow]=useState(false);

  useEffect(() => {
    getJournalApi();
    setLang(i18n.language);
  }, []);

  const handleClose=() => {
    setJournalDetail({});
    setShow(false)
  };

  // Change the language when a button is clicked
  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');
    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };



  function getJournalApi() {
    var formdata=new FormData();
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/getjournal`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setJournal(result.data.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {
      });
  }

  function handleShow(x, id) {
    x.preventDefault();

    var formdata=new FormData();
    formdata.append('id', id);
    var requestOptions={
      method: 'POST',
      body: formdata,
    };
    fetch(`${config.baseUrl}/getjournal/detail`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setJournalDetail(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {
      });

    setShow(true);
  }

  function limitWord(text) {
    const words=text.split(' ');
    if (words.length>30) {
      return words.slice(0, 30).join(' ')+'......';
    } else {
      return text;
    }
  }


  return (
    <div className="main-container">
      <br />
      <h2 className="journals-title lead text-center" style={{ padding: '6px', margin: '0 0 1em 0', letterSpacing: '1px', textTransform: 'uppercase' }}>
        {t('qatar_university_open_journal_system')} </h2>

      <div className="about-site">
        Welcome to The Qatar University Journals Portal. This is publication home of Qatar University Academic Refereed Journals. You can browse, registration and submit papers by navigating to the appropriate Journal page.
      </div>
      <br />
      <div className="journals">

        <ul className="row media-list">


          {
            getJournal?.map((item, index) => {
              return (
                <li className="media col-md-6" key={index}>
                  <div className="media-left">
                    <a href="javascript:void(0);" onClick={(x) => handleShow(x, item.id)}>
                      <img className="media-object" src={`${config.baseImg}/${item.image}`} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="media-heading">
                      <a href="javascript:void(0);" onClick={(x) => handleShow(x, item.id)} rel="bookmark">
                        {(getLang=='en-US'||getLang=='en')? item.name:item.name_ar}
                      </a>
                    </h3>
                    <div className="description" >
                      <p className="para">{(getLang=='en-US'||getLang=='en')? limitWord(item.description):limitWord(item.description_ar)}</p>
                    </div>

                    <ul className="nav nav-pills">
                      <li className="view">
                        <a target="_blank" href={item.view_journal}>
                          {t('View Journal')}
                        </a>
                      </li>
                      <li className="current">
                        <a target="_blank" href={item.current_issue}>
                          {t('Current Issue')}
                        </a>
                      </li>
                      <li className="current">
                        <a target="_blank" href={item.submission}>{t('Submissions')}</a>
                      </li>
                    </ul>

                  </div>
                </li>
              )
            })
          }

        </ul>
      </div>


      <Modal className="author-modal" show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">{(getLang=='en-US'||getLang=='en')? (getJournalDetail.name):(getJournalDetail.name_ar)}</Modal.Title>
          <button onClick={handleClose} className="modal-custom-close"><i className="fas fa-times"></i></button>
        </Modal.Header>
        <Modal.Body>

          <div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <img className="modal-img" src={`${config.baseImg}/${getJournalDetail.image}`} />
                  <p>{(getLang=='en-US'||getLang=='en')? (getJournalDetail.description):(getJournalDetail.description_ar)}</p>
                </div>

              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>

    </div>
  )
}