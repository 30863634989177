import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue, setCustomerLogin } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, removeStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

export default function SignUp() {
  const navigate=useNavigate();
  const signin=() => {
    return navigate("/signin")
  }
  const { t, i18n }=useTranslation();
  const [getCountry, setCountry]=useState([]);
  const [getState, setState]=useState([]);
  const [getLang, setLang]=useState('en');
  const nameRef=useRef();
  const emailRef=useRef();
  const countryRef=useRef();
  const stateRef=useRef();
  const passwordRef=useRef();
  const submitRef=useRef();

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  useEffect(() => {
    setLang(i18n.language);
    getCountryApi();
  }, [])

  function navigateTo(ele, target) {
    ele.preventDefault();
    return navigate(target);
  }

  function isValidEmail(email) {
    const emailPattern=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test method to check if the email matches the pattern
    return emailPattern.test(email);
  }

  const register=() => {

    let password=(passwordRef.current.value).trim();
    let name=(nameRef.current.value).trim();
    let country=(countryRef.current.value).trim();
    let state=(stateRef.current.value).trim();
    let email=(emailRef.current.value).trim();

    var submit=(submitRef.current);

    submit.disabled=true;
    submit.text='Please Wait';
    
    if (password=='') {
      Toastify({
        text: t('Password Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (name=='') {
      Toastify({
        text: t('Name Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (country=='') {
      Toastify({
        text: t('Country Is Required'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (state=='') {
      Toastify({
        text: t('Select State'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    } else if (email==''||!isValidEmail(email)) {
      Toastify({
        text: t('Enter Valid Email'),
        duration: 3000,
        style: {
          background: config.toastError,
        }
      }).showToast();
      return false;
    }

    var myHeaders=new Headers();    
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("name", name);
    formdata.append("country_id", country);
    formdata.append("city_id", state);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/register`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          setCustomerLogin(result.id, result.token);
          return navigate('/myaccount');
        } else {
          Toastify({
            text: result.msg,
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();

        }
      }).catch(error => {
        Toastify({
          text: t('Invalid Login'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();

      }).finally(() => {
        submit.disabled=false;
        submit.text=t('create_account');
      });

  }

  const getCountryApi=() => {   
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/common/getcountry`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result);
          setCountry(result.data);
        } else {
          Toastify({
            text: t('Something Went Wrong'),
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  function getCountryValue(x) {
    x.preventDefault();
    let country_id=(x.target.value);
    
    var myHeaders=new Headers();
    myHeaders.append(getTokenKey(), getTokenValue());
    myHeaders.append('Accept-Language', getLang);

    var formdata=new FormData();
    formdata.append("customer_id", getCustomerId());
    formdata.append("country_id", country_id);
    var requestOptions={
      method: 'POST',
      body: formdata,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${config.baseUrl}/common/getcity`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result);
          setState(result.data);
        } else {
          Toastify({
            text: result.msg,
            duration: 3000,
            style: {
              background: config.toastError,
            }
          }).showToast();
          return false;
        }
      }).catch(error => {
        console.log(error);
        Toastify({
          text: t('Something Went Wrong'),
          duration: 3000,
          style: {
            background: config.toastError,
          }
        }).showToast();
        return false;
      }).finally(() => {
      });
  }

  return (
    <div className="axil-signin-area">
      {/* Start Header */}
      <div className="signin-header">
        <div className="row align-items-center">
          <div className="col-sm-4">
            <a href="javascript:void(0);" onClick={(x) => navigateTo(x, '/')} className="site-logo"><img src="./assets/images/logo/logo.png" alt="logo" /></a>
          </div>
          <div className="col-sm-8">
            <div className="singin-header-btn">
              <p onClick={(x) => navigateTo(x, '/signin')}>{t('already_a_member?')}</p>
              <a href="javascript:void(0);" className="axil-btn btn-bg-secondary sign-up-btn" onClick={(x) => navigateTo(x, '/signin')}>{t('sign_in')}</a>
            </div>
          </div>
        </div>
      </div>
      {/* End Header */}
      <div className="row">
        <div className="col-xl-4 col-lg-6">
          <div className="axil-signin-banner bg_image bg_image--10">
            <h3 className="title">{t('join_us_today_and_surprise_yourself!')}</h3>
          </div>
        </div>
        <div className="col-lg-6 offset-xl-2">
          <div className="axil-signin-form-wrap">
            <div className="axil-signin-form">
              <h3 className="title">{t('i_am_new_here')}</h3>
              <p className="b2 mb--55">{t('enter_your_details_below')}</p>
              <form className="singin-form">
                <div className="form-group">
                  <label>{t('user_name')}</label>
                  <input type="text" ref={nameRef} className="form-control" name="name" />
                </div>
                <div className="form-group">
                  <label>{t('email')}</label>
                  <input type="email" ref={emailRef} className="form-control" name="email" />
                </div>
                <div className="form-group">
                  <label>{t('password')}</label>
                  <input type="password" ref={passwordRef} className="form-control" name="password" />
                </div>
                <div className="form-group">
                  <label>{t('country')}</label>
                  <select ref={countryRef} onChange={(x) => getCountryValue(x)} name="country" id="country" className="select2">
                    <option value="">Select Country</option>
                    {
                      getCountry?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>{(getLang=='en-US' || getLang=='en')? item.name:item.ar_name}</option>
                        )
                      })
                    }
                  </select>
                </div>

                <div className="form-group">
                  <label>{t('State')}</label>
                  <select ref={stateRef} name="country" id="country" className="select2">
                    <option value="">{t('Select State')}</option>
                    {
                      getState?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>{(getLang=='en-US' || getLang=='en')? item.name:item.ar_name}</option>
                        )
                      })
                    }
                  </select>
                </div>

                <div className="form-group">
                  <button type="button" ref={submitRef} onClick={register} className="axil-btn btn-bg-primary submit-btn">{t('create_account')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
