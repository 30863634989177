import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

import { TiTick } from 'react-icons/ti';

import { checkSecureLogin, customerLogout, getCustomerId, getTokenKey, getTokenValue, setCustomerLogin } from "../checkSecureLogin";
import config, { checkStorage, getCurrency, getStorage, removeStorage, toDecimal } from "../Config";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import swal from "sweetalert";

export default function Thankyou() {
    const { t, i18n }=useTranslation();
    const navigate=useNavigate();

    const [getLang, setLang]=useState('en');
    const [getOrderId, setOrderId]=useState('');
    const [getInvoiceNo, setInvoiceNo]=useState('');

    const changeLanguage=(lng) => {
        i18n.changeLanguage(lng);
        const currentLanguage=i18n.language;
        let currDirEle=document.getElementById('head-lang');
        let currDir=currDirEle.getAttribute('dir');

        if (currentLanguage=='ar') {
            currDirEle.setAttribute('dir', 'rtl');
        } else {
            currDirEle.setAttribute('dir', 'ltr');
        }
    };

    useEffect(() => {
        setLang(i18n.language);
        setOrderId(getStorage('order_id'));
        setInvoiceNo(getStorage('invoice_number'));
    }, [])



    function navigateTo(ele, target) {
        ele.preventDefault();
        return navigate(target);
    }

    return (
        <div>
            <section className="error-page onepage-screen-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content text-center pe-0">
                                <TiTick style={{ color: 'green', fontSize: '86px' }} />
                                <h1 className="title">Invoice #: {getInvoiceNo}</h1>
                                <p>Thanks For Shopping With Us</p>
                                <a href="/" className="axil-btn btn-bg-secondary right-icon">Back To Home <i className="fal fa-long-arrow-right"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}