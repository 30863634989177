import React from "react";
import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";
import config from "../Config";
import Button from 'react-bootstrap/Button';
import swal from "sweetalert";
import Modal from 'react-bootstrap/Modal';

export default function About() {
  const { t, i18n }=useTranslation();

  const [getDirectorData, setDirectorData]=useState({});

  const changeLanguage=(lng) => {
    i18n.changeLanguage(lng);
    const currentLanguage=i18n.language;
    let currDirEle=document.getElementById('head-lang');
    let currDir=currDirEle.getAttribute('dir');

    if (currentLanguage=='ar') {
      currDirEle.setAttribute('dir', 'rtl');
    } else {
      currDirEle.setAttribute('dir', 'ltr');
    }
  };

  useEffect(() => {
    getDirectorMsgApi();
  }, [])


  function getDirectorMsgApi() {
    var requestOptions={
      method: 'POST',
    };

    fetch(`${config.baseUrl}/about`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status==='success') {
          console.log(result.data);
          setDirectorData(result.data);
        } else {
          swal({
            icon: result.status,
            title: result.msg
          })
        }
      }).catch(error => {
        swal({
          icon: 'error',
          title: 'Something Went Wrong'
        })
      }).finally(() => {

      });
  }


  return (
    <div>
      <main className="main-wrapper">
        {/* Start Breadcrumb Area  */}
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><a href="/">{t('home')}</a></li>
                    <li className="separator" />
                    <li className="axil-breadcrumb-item active" aria-current="page">{t('about')}</li>
                  </ul>
                  <h1 className="title">{t('about')}</h1>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
      <br />
      <article className="director-article container">
        <div
          dangerouslySetInnerHTML={{ __html: getDirectorData.content }}
        />
      </article>
      <br />
      <br />
    </div>
  )
}