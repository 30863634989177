import React from "react";
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import secureLocalStorage from "react-secure-storage";

export default function OurTeam() {
    const slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Adjust the number of slides to show as needed
        slidesToScroll: 2,
        
    };
    const { t, i18n } = useTranslation();

    // Change the language when a button is clicked
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      const currentLanguage = i18n.language;
      let currDirEle = document.getElementById('head-lang');
      let currDir = currDirEle.getAttribute('dir');
  
      if (currentLanguage == 'ar') {
        currDirEle.setAttribute('dir', 'rtl');
      } else {
        currDirEle.setAttribute('dir', 'ltr');
      }
    };
    return (
        <div className="axil-team-area bg-wild-sand">
            <div className="team-left-fullwidth">
                <div className="container ml--xxl-0">
                    <div className="section-title-wrapper">
                        <span className="title-highlighter highlighter-primary">
                            <i className="fas fa-users" /> {t('our_team')}
                        </span>
                        <h3 className="title">{t('our_team')} </h3>
                    </div>
                    <div className="team-slide-activation slick-layout-wrapper--20 axil-slick-arrow  arrow-top-slide">
                        <Slider {...slickSettings}>
                            <div className="slick-single-layout">
                                <div className="axil-team-member">
                                    <div className="thumbnail">
                                        <img src="./assets/images/team/team-01.png" alt="Rosalina D. Willson" />
                                    </div>
                                    <div className="team-content">
                                        <span className="subtitle">Founder</span>
                                        <h5 className="title">Rosalina D. Willson</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-single-layout">
                                <div className="axil-team-member">
                                    <div className="thumbnail">
                                        <img src="./assets/images/team/team-02.png" alt="Ukolilix X. Xilanorix" />
                                    </div>
                                    <div className="team-content">
                                        <span className="subtitle">CEO</span>
                                        <h5 className="title">Ukolilix X. Xilanorix</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-single-layout">
                                <div className="axil-team-member">
                                    <div className="thumbnail">
                                        <img src="./assets/images/team/team-02.png" alt="Ukolilix X. Xilanorix" />
                                    </div>
                                    <div className="team-content">
                                        <span className="subtitle">CEO</span>
                                        <h5 className="title">Ukolilix X. Xilanorix</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-single-layout">
                                <div className="axil-team-member">
                                    <div className="thumbnail">
                                        <img src="./assets/images/team/team-02.png" alt="Ukolilix X. Xilanorix" />
                                    </div>
                                    <div className="team-content">
                                        <span className="subtitle">CEO</span>
                                        <h5 className="title">Ukolilix X. Xilanorix</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-single-layout">
                                <div className="axil-team-member">
                                    <div className="thumbnail">
                                        <img src="./assets/images/team/team-02.png" alt="Ukolilix X. Xilanorix" />
                                    </div>
                                    <div className="team-content">
                                        <span className="subtitle">CEO</span>
                                        <h5 className="title">Ukolilix X. Xilanorix</h5>
                                    </div>
                                </div>
                            </div>
                            {/* Add more team members here */}
                        </Slider>
                    </div>
                </div>
            </div>
            <p>Mollit esse id nisi amet. Nulla elit proident veniam tempor magna tempor laboris ex Lorem dolor et. Exercitation duis ipsum qui mollit exercitation cupidatat voluptate tempor. Do quis eu duis ad fugiat reprehenderit fugiat et ullamco velit aliqua minim incididunt id. Lorem ad elit laboris ea labore labore labore sunt do occaecat veniam voluptate.</p>
        </div>

    )
}